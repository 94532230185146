@use 'defaults';
@use 'util';
@use 'bas_notch_const';

// bmmii = bas-modal-menu-item-icon

$modal-options-font-size: 16px;
$modal-options-color: #1f1f1f;
$modal-options-fill: #b8b8b8;
$modal-options-background-phone: rgba(0, 0, 0, .5);
$modal-options-item-border: 1px solid #ececec;

$modal-options-item-font: .9em;
$modal-options-item-width: 17em;
$modal-options-item-padding-side: 1em;
$modal-options-item-padding-top-bottom: .8em;
$modal-options-item-height: 1.5em;

$modal-options-phone-bottom-offset: defaults.$main-footer-height-phone + 10px;

$modal-options-item-total-height: $modal-options-item-height +
  2 * $modal-options-item-padding-top-bottom;

$modal-options-width: $modal-options-item-width;
$modal-options-height: $modal-options-item-total-height;
$modal-options-max-width: 90%;
$modal-options-max-height: 80%;
$modal-options-border-radius: .5em;
$modal-options-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);

$modal-lock-size: .8em;
$modal-cloud-size: 1em;

$modal-context-menu-padding: 12px;

.modal-options {
  @include util.absolute-match-parent();

  display: block;
  font-size: $modal-options-font-size;
  z-index: 3;

  @media (max-width: #{defaults.$media-phone-width}) {
    background-color: $modal-options-background-phone;
  }

  .model-item-icon-mute {
    display: none;
  }

  .model-item-icon-volume {
    display: block;
  }

  &.modal-is-muted-device {
    .model-item-icon-mute {
      display: block;
      fill: defaults.$color-red-light;
    }

    .model-item-icon-volume {
      display: none;
    }
  }
}

#modal-library-options-menu-item {
  // !!! For reading with JavaScript !!!
  content: '#{$modal-options-item-total-height}';
}

.modal-menu {
  height: auto;
  position: absolute;
  left: 50%;
  top: 5%;
  width: $modal-options-item-width;
  max-width: $modal-options-max-width;
  min-height: $modal-options-height;
  max-height: $modal-options-max-height;
  max-height: calc(#{$modal-options-max-height} - #{bas_notch_const.$notch-safe-bottom});
  border-radius: $modal-options-border-radius;
  transform: translate(-50%, 0);
  background-color: defaults.$color-foreground;
  font-size: $modal-options-item-font;
  color: $modal-options-color;
  box-shadow: $modal-options-box-shadow;
  overflow-x: hidden;
  overflow-y: auto;
  transition: height 0.2s;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: #{defaults.$media-phone-width}) {
    width: 90%;
    // !important needed to override style directly on element
    left: 50% !important;
    right: auto !important;
    transform: translate3d(-50%, 0, 0) !important;

    // Only align modal to bottom when there is NO custom position set on mobile
    &:not(.modal-menu-force-custom-position) {
      @media (max-height: #{defaults.$media-phone-width}) {
        top: auto !important;
        bottom: calc(#{$modal-options-phone-bottom-offset} + #{bas_notch_const.$notch-safe-bottom}) !important;
      }
    }
  }

  &.modal-menu-instant-height {
    transition: unset;
  }
}

.modal-context-menu-connect {
  @media (min-width: #{defaults.$media-phone-width}) {
    margin-top: 0;
  }
}

.modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform defaults.$standard-animation-time;

  &.modal-container-user-selected {
    min-height: 9.3em;

    .modal-menu-item-big-2 {
      display: none;
    }

    .modal-item-selected {
      display: block;
    }
  }
}

.modal-spacer {
  position: relative;
  top: 0;
  left: 0;
  opacity: 0;
}

.modal-profiles {
  position: relative;
  border-bottom: $modal-options-item-border;
}

.modal-standard {
  left: 0;

  &.show-playlists {
    transform: translate(-100%, 0);
  }
}

@mixin modal-extended-base() {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

.modal-extended {
  @include modal-extended-base();

  left: 100%;

  &.show-playlists {
    transform: translate(-100%, 0);
  }
}

.modal-extended-static {
  @include modal-extended-base();
}

.flex-static,
.flex-back,
.flex-new {
  flex: 0 0 auto;
}

.flex-container {
  flex: 1 1 auto;
}

.modal-menu-item-container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .modal-menu-item:last-child {
    border: none;
  }
}

$modal-options-item-icon-width: 16px;
$modal-options-item-icon-height: $modal-options-item-icon-width;
$modal-options-item-background-active: #aaa;

@mixin modal-menu-item-base() {
  @include defaults.font-roboto-light;
  // !!! For reading with JavaScript !!!
  content: '#{$modal-options-item-total-height}';
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 0 0 auto;
  height: $modal-options-item-total-height;
  padding: $modal-options-item-padding-top-bottom $modal-options-item-padding-side;
  vertical-align: middle;
  border-bottom: $modal-options-item-border;
  box-sizing: border-box;
  white-space: nowrap;
  transition: color defaults.$standard-animation-time;
}

@mixin modal-menu-item() {
  @include util.button-cursor();
  @include modal-menu-item-base();

  &:active,
  &.active {
    color: defaults.$color-highlight-on-light;

    .modal-item-icon {
      fill: defaults.$color-highlight-on-light;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.modal-item-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.modal-item-password {
  height: 30%;
  margin: 10px 0;
  // We use 'width: 0; and 'overflow: hidden;' here instead of
  //  'display: none;' or 'visibility: hidden;', since invisible elements can
  //  not be focused.
  width: 0;
  overflow: hidden;
}

.modal-item-password-wrapper {
  @include defaults.font-roboto-regular;

  position: relative;
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  background-color: #cecece;
  vertical-align: middle;
  white-space: nowrap;

  .login-credentials-password-button {
    fill: #909090;
  }
}

.modal-container-user-selected .modal-item-has-password.modal-item-selected {
  .modal-item-password {
    width: 100%;
  }

  .modal-item-wrapper {
    height: 60%;
  }

  &.modal-menu-item-big-2 {
    height: 9.3em;
    display: block;
  }
}

.modal-menu-item {
  @include modal-menu-item();
}

.modal-menu-list-item {
  @include modal-menu-item();

  padding: 1em;
  display: block;
}

.modal-menu-item-special {
  @include modal-menu-item();

  color: defaults.$color-accent-red;
  fill: defaults.$color-accent-red;

  &:active {
    color: defaults.$color-accent-red;

    .modal-item-icon {
      fill: defaults.$color-accent-red;
    }
  }
}

$modal-options-item-big-border-size: 4px;
$modal-options-item-big-icon-border: $modal-options-item-big-border-size solid $modal-options-fill;
$modal-options-item-big-icon-border-active: $modal-options-item-big-border-size solid defaults.$color-highlight-on-dark;

$modal-options-item-big-total-height-1: 3 * $modal-options-item-total-height;
$modal-options-item-big-icon-size-1: $modal-options-item-big-total-height-1
  - 2 * $modal-options-item-padding-top-bottom
  - $modal-options-item-height;
$modal-options-item-big-margin: 0 0 0 0;

.modal-menu-item-big-1 {
  @include modal-menu-item();

  flex-flow: column nowrap;
  height: $modal-options-item-big-total-height-1;

  > .modal-item-icon {
    flex: 1 1 auto;
    width: $modal-options-item-big-icon-size-1;
    //height: $modal-options-item-big-icon-size;
    margin: $modal-options-item-big-margin;
    border: $modal-options-item-big-icon-border;
    border-radius: 100%;
    box-sizing: border-box;

    > svg {
      @include util.absolute-center();

      fill: $modal-options-fill;
    }
  }

  > .modal-item-text {
    height: $modal-options-item-height;
  }
}

$modal-options-item-big-total-height-2: 2 * $modal-options-item-total-height;
// Use pixel equivalent value of following calculation, to fix visual bug
//  in profile border.
//
// $modal-options-item-big-total-height-2
//  - 2 * $modal-options-item-padding-top-bottom
$modal-options-item-big-icon-size-2: 66px;
$modal-options-item-big-border: #cecece;

.modal-menu-item-big-2 {
  @include modal-menu-item();

  height: $modal-options-item-big-total-height-2;

  .modal-item-icon {
    flex: 0 0 auto;
    width: $modal-options-item-big-icon-size-2;
    height: $modal-options-item-big-icon-size-2;
    border: $modal-options-item-big-icon-border;
    box-sizing: border-box;
    border-radius: 100%;
    overflow: hidden;
    transition: border defaults.$standard-animation-time;

    > svg {
      @include util.absolute-center();

      top: .4em;
      transform: translate(-50%, 0);
      fill: $modal-options-fill;
      transition: fill defaults.$standard-animation-time;
    }
  }

  .modal-item-text-subtitle {
    color: defaults.$color-text-grey;
    fill: $modal-options-fill;
    transition: fill defaults.$standard-animation-time, color defaults.$standard-animation-time;
  }

  &.modal-menu-item-big-border {
    border-bottom: 2px solid $modal-options-item-big-border;
  }

  &:active {
    > .modal-item-icon {
      border: $modal-options-item-big-icon-border-active;

      > svg {
        fill: defaults.$color-highlight-on-dark;
      }
    }

    .modal-item-text-subtitle {
      color: defaults.$color-highlight-on-dark;
      fill: $modal-options-fill;
    }
  }
}

.modal-menu-item-inner-expand-touch::after {
  content: '';
  position: absolute;
  left: -$modal-options-item-padding-side;
  right: -$modal-options-item-padding-side;
  top: -$modal-options-item-padding-top-bottom;
  bottom: -$modal-options-item-padding-top-bottom;
}

.modal-item-text-subtitle-name {
  max-width: calc(100% - 1.2em);
  display: inline-block;
}

.modal-item-text-subtitle-cloud,
.modal-item-text-subtitle-lock {
  position: relative;
  display: inline-block;

  svg {
    @include util.absolute-center();

    height: 100%;
    width: 100%;
  }
}

.modal-item-text-subtitle-cloud {
  height: $modal-cloud-size;
  width: $modal-cloud-size;
  top: -.1em;
}

.modal-item-text-subtitle-lock {
  height: $modal-lock-size;
  width: $modal-lock-size;
  top: -.27em;
}

.bas-device-core-client {
  .modal-log-out-text {
    display: none;
  }

  .modal-profiles {
    display: none;
  }

  .modal-current-user {
    height: 3.1em;

    &:active {
      .modal-item-text-subtitle {
        color: defaults.$color-text-grey;
      }
    }

    .modal-item-icon {
      display: none;
    }

    .modal-item-text-subtitle {
      margin-left: 30.4px;
      color: defaults.$color-text-dark;
    }

  }
}

$modal-options-context-title-deezer-height: 1.6em;
$modal-options-context-title-spotify-height: 2em;
$modal-options-context-background-color: defaults.$color-foreground-disabled;

.modal-menu-item-context-title {
  @include modal-menu-item-base();

  border-bottom: none;
  background-color: $modal-options-context-background-color;

  > svg {
    @include util.absolute-center();
  }
}

.context-logo-tidal,
.context-logo-deezer {
  > svg {
    height: $modal-options-context-title-deezer-height;
  }
}

.context-logo-spotify {
  > svg {
    height: $modal-options-context-title-spotify-height;
    fill: defaults.$color-spotify-green;
  }
}

.modal-menu-item-context-content {
  @include modal-menu-item-base();

  justify-content: center;
  border-bottom: none;
  background-color: $modal-options-context-background-color;
  text-align: center;
}

.modal-menu-item-title {
  @include modal-menu-item-base();

  border-bottom: none;
  background-color: $modal-options-context-background-color;
}

$modal-options-item-icon-margin: 0 1em 0 0;
$modal-options-item-icon-big-width: 125%;
$modal-item-text-padding-top: .15em;
$modal-item-text-min-height: 1em;

@mixin modal-item-icon {
  position: relative;
  flex: 0 0 auto;
  width: $modal-options-item-icon-width;
  height: $modal-options-item-icon-height;
  margin: $modal-options-item-icon-margin;
  fill: $modal-options-fill;
  vertical-align: middle;
  transition: fill defaults.$standard-animation-time;
  background-size: cover;
  background-position: center;
  background-image: none;
  // Needed for iOS,
  // otherwise SVG will show outside circle despite overflow: hidden
  z-index: 0;

  svg {
    @include util.absolute-center();

    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &.big {
    svg {
      width: $modal-options-item-icon-big-width;
      height: $modal-options-item-icon-big-width;
    }
  }
}

.modal-item-icon {
  @include modal-item-icon();
}

.bmmii-thermostat {
  svg {
    width: 20px;
  }
}

.modal-item-icon-wrapper {
  @include util.absolute-center();

  display: block;
  width: 100%;
  height: 100%;
}

.bas-rotate-icon {
  width: $modal-options-item-icon-width;
  height: $modal-options-item-icon-height;
  transition: transform defaults.$standard-animation-time;
  transform: rotate(0deg) translate(-50%, -50%);
  transform-origin: top left;

  &.rotated {
    transform: rotate(45deg) translate(-50%, -50%);
  }
}

.modal-item-icon-special {
  @include modal-item-icon();

  fill: defaults.$color-accent-red;
}

.modal-item-icon-placeholder {
  opacity: 0;
}

.modal-item-avatar {
  @include util.absolute-center();

  width: 105%;
  border-radius: 100%;
}

.modal-item-text {
  position: relative;
  width: 100%;
  vertical-align: middle;
  padding-top: $modal-item-text-padding-top;
  min-height: $modal-item-text-min-height;
}

.modal-item-text-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.modal-item-text-suffix {
  position: relative;
  width: auto;
  margin-left: 5px;
  vertical-align: middle;
  padding-top: $modal-item-text-padding-top;
  min-height: $modal-item-text-min-height;
  color: defaults.$color-background-shadow;
}

.no-border {
  border: none;
}

.flex-new {
  border-top: $modal-options-item-border;
}

$modal-options-item-input-font: 1.05em;

.modal-menu-input {
  font-size: $modal-options-item-input-font;
}

.mii-favourite {

  .mii-favourite-save,
  .mii-favourite-empty {
    opacity: 1;
  }

  .mii-favourite-remove,
  .mii-favourite-full {
    opacity: 0;
  }

  &.mii-favourited {

    .mii-favourite-save,
    .mii-favourite-empty {
      opacity: 0;
    }

    .mii-favourite-remove,
    .mii-favourite-full {
      opacity: 1;
    }
  }
}

.mii-favourite-text,
.mii-favourite-icon {
  transition: opacity defaults.$standard-animation-time;
}
