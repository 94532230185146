@use 'sass:math';
@use 'defaults';
@use 'bas_notch_const';
@use 'tiles_const';
@use 'basstyle_const';
@use 'zones_const';
@use 'util';

// brh = bas-rooms-header

// Header

$bas-objects-header-padding: 0 1em;
$bas-objects-header-spacing: .6em;
$bas-objects-header-item-margin: .85em .4em;
$bas-objects-header-item-padding: .25em $bas-objects-header-spacing;
$bas-objects-header-item-radius: .85em;
$bas-objects-header-item-font-size: 16px;
$bas-objects-header-item-font-size-phone: 12px;
$bas-objects-header-controls-margin: 0 auto 0 $bas-objects-header-spacing;

.bas-objects-header {
  @include defaults.font-roboto-regular;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: calc(#{bas_notch_const.$notch-safe-left} + 16px);
  padding-right: calc(#{bas_notch_const.$notch-safe-right} + 16px);
  background-color: defaults.$color-background-dark-transparent-light;
  font-size: $bas-objects-header-item-font-size;
  color: defaults.$color-text-lighter;
  box-sizing: border-box;
  min-height: 3.4em;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $bas-objects-header-item-font-size-phone;
  }
}

.bas-objects-header-sections {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  box-sizing: border-box;
  color: defaults.$color-text-lighter;
  padding-left: defaults.$navigation-bar-mask-padding;
  padding-right: defaults.$navigation-bar-mask-padding;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  mask: defaults.$navigation-bar-mask;
}

$bas-object-header-group-separator-size: 3px;

.bas-objects-header-group {
  display: inline-flex;
  align-items: center;

  &::after {
    content: '';
    width: $bas-object-header-group-separator-size;
    height: $bas-object-header-group-separator-size;
    background-color: defaults.$color-text-lighter;
    border-radius: 9999px;
    display: inline-block;
    margin: 0 .5em;
  }

  &:last-child::after {
    content: none;
  }
}

.bas-objects-header-item {
  @include util.button-cursor();

  display: inline-block;
  margin: $bas-objects-header-item-margin;
  padding: $bas-objects-header-item-padding;
  border-radius: $bas-objects-header-item-radius;
  vertical-align: middle;

  &.active {
    color: defaults.$color-text-lighter-inverse;
    background-color: defaults.$color-text-lighter;
  }

  &.semi-active {
    color: defaults.$color-text-lighter-inverse;
    background-color: defaults.$color-text-grey;
  }
}

.bas-objects-header-controls {
  @include defaults.font-roboto-regular;

  flex: 0 0 auto;
  color: defaults.$color-highlight-on-dark;
  margin: $bas-objects-header-controls-margin;
  display: flex;
  align-items: center;
}

.bas-objects-header-controls-edit,
.bas-objects-header-controls-add {
  @include util.button-cursor();
}

$quad-edit-size: 1.7em;

.bas-objects-header-controls-edit-quad {
  position: relative;
  width: $quad-edit-size;
  height: $quad-edit-size;
  margin-right: $bas-objects-header-spacing;
  cursor: pointer;

  svg {
    @include util.absolute-center;

    width: 100%;
    fill: defaults.$color-text-lighter;
    transition: fill defaults.$standard-animation-time;
  }

  &:active {
    svg {
      fill: defaults.$color-highlight-on-dark;
      transition: none;
    }
  }
}

.bas-objects-header-controls-add {
  margin-right: $bas-objects-header-spacing;
}

.bas-objects-header-controls-edit-item-done,
.bas-objects-header-controls-edit-quad {
  display: none;
}

.bas-objects-header-controls-edit-item-edit {
  display: block;
}

.bas-objects-header-controls-add {
  display: none;
}

.bas-objects-header-controls--show-edit {

  .bas-objects-header-controls-edit-item-done {
    display: block;
  }

  .bas-objects-header-controls-edit-item-edit {
    display: none;
  }

  .bas-objects-header-controls-edit-quad {
    display: block;
  }

  &.bas-objects-header-controls--can-add {
    .bas-objects-header-controls-add {
      display: block;
    }
  }
}

.bas-objects-header-toggle {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  margin-left: 1em;

  @media (max-width: #{defaults.$media-phone-width}) {
    width: 24px;
    height: 24px;
  }

  svg {
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$standard-animation-time;
  }

  &:active {

    svg {
      fill: defaults.$color-light-control-buttons-active;
      transition: fill 0s;
    }
  }
}

.bas-objects-header-toggle-icon {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity defaults.$standard-animation-time defaults.$animation-timing;

  > svg {
    @include util.absolute-center();
  }
}

.bas-objects-header-toggle-icon-music {
  > svg {
    width: 20px;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    > svg {
      width: 24px;
    }
  }
}

.bas-objects-header-toggle-icon-home {
  > svg {
    width: 26px;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    > svg {
      width: 30px;
    }
  }
}

.bas-objects-header-toggle-icon-thermostats {
  > svg {
    width: 22px;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    > svg {
      width: 26px;
    }
  }
}

.bas-objects-header-toggle-icon-intercom {
  > svg {
    width: 22px;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    > svg {
      width: 26px;
    }
  }
}

// Body

$bas-object-spacing-hor: 4px;
$bas-object-spacing-ver: 4px;

.bas-objects-body {

  .bas-object-container {
    margin-right: -(tiles_const.$bas-tile-spacing-hor-p);
    margin-bottom: -(tiles_const.$bas-tile-spacing-ver-p);

    @media (min-width: #{defaults.$media-phone-width}) {
      margin-right: -(tiles_const.$bas-tile-spacing-hor);
      margin-bottom: -(tiles_const.$bas-tile-spacing-ver);
    }
  }

  &.bas-wide-objects {
    .bas-object-container {
      margin-right: -$bas-object-spacing-hor;
      margin-bottom: -$bas-object-spacing-ver;
    }
  }
}

// Object group

$bas-object-group-list-padding: .8em 0;

$bas-object-groups-font-size: 16px;
$bas-object-groups-font-size-phone: 12px;
$bas-object-group-width: 100% - 2 * zones_const.$bas-object-group-margin;
$bas-object-group-margin-value: .4em;
$bas-object-group-margin: 0 auto $bas-object-group-margin-value auto;
$bas-object-group-padding: 0;

.bas-object-group {
  position: relative;
  display: block;
  width: $bas-object-group-width;
  margin: $bas-object-group-margin;
  padding: $bas-object-group-padding;
  font-size: $bas-object-groups-font-size;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $bas-object-groups-font-size-phone;
  }

  .bas-object-container {
    // This margin is only effective when the container is not followed by
    //  a title line (collapsing margins)
    margin-top: $bas-object-group-margin-value;
  }
}

.bas-object-group.bmc-wrapper {
  position: absolute;
}

.bas-notch-filler {
  width: 100%;
  height: 0;
  height: bas_notch_const.$notch-safe-bottom;
}

.bas-object-message-hide {
  .bas-object-message {
    display: none;
  }
}

.bas-object-message {
  @include util.absolute-center();
  @include defaults.font-roboto-regular;

  &.bas-object-message-on-dark {
    color: defaults.$color-foreground;
  }

  &.bas-object-message-on-light {
    color: defaults.$color-text-black;
  }
}

.bas-object-group-scrolling {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: defaults.$main-header-height-phone;
  right: 0;
  bottom: 0;
  left: 0;

  @media (min-width: #{defaults.$media-phone-width}) {
    top: defaults.$bmsc-header-height;
  }
}

// Object

$bas-object-background-color: rgba(0, 0, 0, .25);
$bas-object-darker-background-color: rgba(0, 0, 0, .35);
$bas-object-font-size: 1em;
$p-bas-object-width: 99.75%;

$bas-object-image-width: 104px;
$bas-object-image-height: $bas-object-image-width;
$bas-object-image-svg-width: 64px;
$bas-object-image-svg-height: $bas-object-image-svg-width;
$bas-object-image-svg-padding: ($bas-object-image-width - $bas-object-image-svg-width)*0.5;
$bas-object-image-transparent: rgba(0, 0, 0, 0);
$bas-object-image-animation: .5s;

$bas-object-source-image-width: 78px;
$bas-object-source-image-height: $bas-object-source-image-width;
$bas-object-source-image-svg-width: 46px;
$bas-object-source-image-svg-height: $bas-object-source-image-svg-width;
$bas-object-source-image-svg-padding: ($bas-object-source-image-width - $bas-object-source-image-svg-width)*0.5;

$bas-object-info-header-vertical-padding: .4em;
$bas-object-info-fill: rgba(defaults.$color-foreground, .65);
$bas-object-info-padding: .4em 1em;
$bas-object-info-header-padding: $bas-object-info-header-vertical-padding 1em;
$bas-object-info-font-size: .8em;
$bas-object-info-font-size-phone: 1em;

$bas-object-info-header-join-margin: 0 1em;
$bas-object-info-header-join-margin-phone: 0 .4em;

$bas-object-info-current-height: 2.2em;
$bas-object-info-current-icon-height: 1.2em;
$bas-object-info-current-icon-width: $bas-object-info-current-icon-height;
$bas-object-info-current-icon-margin: 0 .4em 0 0;
$bas-object-info-current-icon-color: rgba(defaults.$color-foreground, .2);
$bas-object-info-current-source-max-width: 50%;
$bas-object-info-current-source-margin: 0 0 0 .4em;

$bas-object-info-volume-margin: -5px 0 0 0; // Quick fix for slider
$bas-object-info-volume-padding: .2em 1em 0 1em;
$bas-object-info-volume-icon-width: 16px;
$bas-object-info-volume-icon-height: $bas-object-info-volume-icon-width;
$bas-object-info-volume-icon-padding: 0 .35em;
$bas-object-info-volume-non-slider-width: 2em;
$bas-object-info-volume-slider-margin: 0 1em;
$bas-object-info-volume-font-size: 1.4em;

$bas-object-info-source-padding: 1em;
$bas-object-info-source-font-size: 1em;
$bas-object-info-source-name-margin: 0 1em 0 0;
$bas-object-info-source-name-title-font-size: 1.5em;
$bas-object-info-source-name-subtitle-font-size: .8em;
$bas-object-info-source-name-subtitle-color: rgba(defaults.$color-foreground, .35);
$bas-object-info-source-name-subtitle-color-dark: rgba(defaults.$color-foreground, .7);
$bas-object-info-source-icons-icon-width: 20px;
$bas-object-info-source-icons-icon-height: $bas-object-info-source-icons-icon-width;
$bas-object-info-source-icons-playing-margin: auto 0 auto 6px;
$bas-object-info-source-icons-arp-margin: auto 0 auto 6px;
$bas-object-info-source-icons-arp-width: 20px;
$bas-object-info-source-icons-spotify-width: 20px;
$bas-object-info-source-icons-spotify-height: 20px;
$bas-object-info-source-icons-spotify-margin: auto 0 auto 6px;
$bas-object-info-source-icons-delete-width: 32px;
$bas-object-info-source-icons-delete-height: 32px;

@function bas-object-width ($num-of-hor-objects) {
  @return calc(#{math.div(100%, $num-of-hor-objects)} - #{$bas-object-spacing-hor});
}

.bas-object-container {
  margin-right: -$bas-object-spacing-hor;
  margin-bottom: -$bas-object-spacing-ver;
}

@mixin bas-object-info-body-bottom-enabled() {
  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    position: relative;
    width: 100%;
  }
}

@mixin bas-object-player-grey {
  .bas-music-icon {
    fill: $bas-object-info-current-icon-color;
  }

  .name-element,
  .bas-object-info-body-current-title {
    color: $bas-object-info-current-icon-color;
  }
}

.bas-object {
  @include defaults.font-roboto-regular;

  position: relative;
  display: inline-block;
  width: bas-object-width(1);
  vertical-align: top;
  background-color: defaults.$color-black-bg-trans;
  text-align: left;
  font-size: $bas-object-font-size;
  color: defaults.$color-foreground;
  overflow: hidden;

  &:not(.bas-object-full-size) {
    margin-right: $bas-object-spacing-hor;
    margin-bottom: $bas-object-spacing-ver;

    @media (min-width: #{defaults.$media-width-medium}) {
      width: bas-object-width(2);
    }

    @media (min-width: #{defaults.$max-width-ipad-pro-landscape + 1px}) {
      width: bas-object-width(3);
    }

    @media (min-width: #{defaults.$media-width-xxxlarge}) {
      width: bas-object-width(4);
    }
  }

  &.bas-object-darker {
    background-color: defaults.$color-black-dark-bg-trans-dark;

    .subtitle {
      color: $bas-object-info-source-name-subtitle-color-dark;
    }
  }

  .bas-object-info-header-join {
    display: none;
  }

  .bas-object-info-header-avroom-select {
    display: none;
  }

  .bas-object-volume-muted {
    display: none;
  }

  .bas-object-spinner {
    display: none;
  }

  // For sources, smaller
  &.source {
    @include util.button-cursor();

    .bas-object-image-container {
      height: $bas-object-source-image-height;
      width: $bas-object-source-image-width;

      .bas-music-icon {
        position: relative;
        height: $bas-object-source-image-svg-height;
        width: $bas-object-source-image-svg-width;
        padding: $bas-object-source-image-svg-padding;

        svg {
          @include util.absolute-center();

          height: $bas-object-source-image-svg-height;
          width: $bas-object-source-image-svg-width;
        }
      }

      .player-playing-cover {
        height: $bas-object-source-image-height;
        width: $bas-object-source-image-width;
      }
    }

    .info {
      height: $bas-object-source-image-height;
    }

    .bas-source-barp-icon {
      display: none;
    }

    &.bas-source-arp {

      .info.source {

        .bas-source-barp-icon {
          display: inline-block;
          margin: $bas-object-info-source-icons-arp-margin;
          width: $bas-object-info-source-icons-arp-width;
        }
      }
    }

    &.bas-source-spotify {

      .info.source {

        .bas-source-barp-icon {
          display: inline-block;
          margin: $bas-object-info-source-icons-spotify-margin;
          width: $bas-object-info-source-icons-spotify-width;
          height: $bas-object-info-source-icons-spotify-height;
        }
      }
    }

    &.bas-source-can-pair {

      .info.source {

        .bas-object-source-pair {
          display: block;
        }
      }
    }

    &.bas-source--no-playing {

      // Deep nesting required for specificity

      .info.source {

        .bas-music-icons {

          > .bas-music-icon {
            &.playing {
              display: none;
            }
          }
        }
      }
    }
  }

  &.favourite {
    @include util.button-cursor();

    .bas-object-image-container {
      position: relative;
      border-right: none;

      .player-playing-cover {
        position: relative;
        height: 100%;
        width: 100%;

        .cover {
          position: relative;
          height: 100%;
          width: 100%;

          .image {
            position: relative;
            display: inline-block;
            height: auto;
          }

          &.single {
            .image {
              height: 100%;
              width: 100%;
            }
          }

          &.multiple {
            .image {
              width: 50%;
            }
          }
        }
      }

      .radio-logo {
        position: relative;
        height: 100%;
        width: 100%;
      }

      .radio-logo-bg {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0;
        background: no-repeat local center;
        background-size: contain;
      }
    }
  }

  .info {
    height: $bas-object-image-height;
    overflow: hidden;
    vertical-align: top;
    fill: $bas-object-info-fill;
  }

  .info {
    &.source {
      @include defaults.font-roboto-light;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: $bas-object-info-source-padding;
      box-sizing: border-box;
      font-size: $bas-object-info-source-font-size;
      color: defaults.$color-foreground;
      fill: $bas-object-info-fill;

      .bas-object-source-pair {
        display: none;
        flex: 0 0 auto;
        height: 24px;
        padding: 1px 0.5em;
        line-height: 24px;
      }

      .info-source {
        flex: 0 0 auto;
        align-self: center;
      }

      .object-info {
        position: relative;
        flex: 1 1 auto;
        margin: $bas-object-info-source-name-margin;
        vertical-align: middle;
        text-align: left;
        overflow: hidden;

        .name-element {
          width: 100%;
          overflow: hidden;
          vertical-align: middle;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .title {
          font-size: $bas-object-info-source-name-title-font-size;
        }

        .title-smaller {
          font-size: 1em;
          font-weight: bold;
        }

        .subtitle {
          @include defaults.font-roboto-regular;

          font-size: $bas-object-info-source-name-subtitle-font-size;
          color: $bas-object-info-source-name-subtitle-color;
        }
      }

      .bas-music-icons {
        text-align: right;

        > .bas-music-icon {
          height: $bas-object-info-source-icons-icon-height;
          width: $bas-object-info-source-icons-icon-width;
          vertical-align: middle;

          &.arp {
            margin: $bas-object-info-source-icons-arp-margin;
            width: $bas-object-info-source-icons-arp-width;
          }

          &.spotify {
            margin: $bas-object-info-source-icons-spotify-margin;
            width: $bas-object-info-source-icons-spotify-width;
            height: $bas-object-info-source-icons-spotify-height;
          }

          &.playing {
            display: inline-block;
            margin: $bas-object-info-source-icons-playing-margin;
          }

          &.delete {
            display: none;
            width: $bas-object-info-source-icons-delete-width;
            height: $bas-object-info-source-icons-delete-height;
          }
        }
      }
    }

    &.intercom {
      @include defaults.font-roboto-light;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: $bas-object-info-source-padding;
      box-sizing: border-box;
      font-size: $bas-object-info-source-font-size;
      color: defaults.$color-foreground;
      fill: $bas-object-info-fill;

      .info-intercom {
        flex: 0 0 auto;
        align-self: center;
      }

      .object-info {
        position: relative;
        flex: 1 1 auto;
        margin: $bas-object-info-source-name-margin;
        vertical-align: middle;
        text-align: left;
        overflow: hidden;

        .name-element {
          width: 100%;
          overflow: hidden;
          vertical-align: middle;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .title {
          font-size: $bas-object-info-source-name-title-font-size;
        }

        .subtitle {
          @include defaults.font-roboto-regular;

          font-size: $bas-object-info-source-name-subtitle-font-size;
          color: $bas-object-info-source-name-subtitle-color;
        }
      }

      .info-intercom-icon {
        flex: 0 0 25px;
        position: relative;

        svg {
          @include util.absolute-center();

          width: 100%;
        }
      }
    }
  }

  .bas-object-spinner {
    width: $bas-object-source-image-height;
    height: $bas-object-source-image-height;
    position: absolute;
    background-color: defaults.$color-black-darker-bg-trans;
    animation: fadeIn defaults.$standard-animation-time;

    .bas-object-inner {
      @include util.absolute-center();

      width: 35%;

      .bas-spinner-swoop .bas-spinner-swoop-circle .bas-spinner-swoop-circle-inner {
        border-width: 3px;
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  &.music.bas-room--music--can-adjust-volume {

    .bas-object-info-body-bottom {
      @include bas-object-info-body-bottom-enabled();
    }

    .bas-object-info-body-volume {
      transform: translateY(0);
    }
  }

  &.music.bas-room--music--has-settings {

    &.bas-room--music--show-settings-button {

      .bas-object-info-body-volume {
        transform: translateY(100%);
      }

      .bas-object-info-body-button-wrapper {
        transform: translateY(0);
      }
    }
  }

  &.music.bas-room--music--is-unavailable,
  &.video.bas-room--video--is-unavailable {
    @include bas-object-player-grey();

    .bas-object-info-header {
      color: defaults.$color-text-light-secondary;
    }
  }

  &.music.room-show-join {

    .bas-object-info-header-join {
      display: block;

      // TODO: When using 'max-width' we should always add ' - 1' to the values to
      //  get consistent breakpoints when compared to 'min-width' media queries.
      //  Since this is often forgotten and also goes against a mobile-first
      //  approach, we should try to only use 'min-width' media queries instead.
      @media (max-width: #{defaults.$media-phone-width}) {
        display: inline-block;
      }
    }
  }

  &.music.bas-room--music--can-group {

    .bas-object-info-header-avroom-select {
      display: inline-block;
      flex: 0 0;
      min-width: 14px;
      font-size: 1em;
      margin-left: 1em;
    }
  }

  &.music.room-show-source-type-icon {

    .bas-object-info-body-current-icon {
      display: block;
    }
  }

  &.music.bas-room--music--is-muted {

    .bas-object-volume-muted {
      display: block;
    }

    .bas-object-volume-audible {
      display: none;
    }
  }

  &.bas-object-player-grey {
    @include bas-object-player-grey();
  }

  &.video.bas-room--video--is-unavailable {
    @include bas-object-player-grey();

    .bas-object-info-header {
      color: defaults.$color-text-light-secondary;
    }
  }

  &.bas-object-player-loading {

    .bas-object-spinner {
      display: block;
    }
  }

  &.video.bas-room--video--can-adjust-volume {

    .bas-object-info-body-bottom {
      @include bas-object-info-body-bottom-enabled();
    }

    .bas-object-info-body-volume {
      transform: translateY(0);
    }
  }

  &.video.bas-room--video--is-muted {

    .bas-object-volume-muted {
      display: block;
    }

    .bas-object-volume-audible {
      display: none;
    }
  }
}

.bas-device-browser {

  .bas-object:not(.bas-object-full-size) {
    @media (min-width: #{defaults.$media-width-xlarge}) {
      width: bas-object-width(3);
    }

    @media (min-width: #{defaults.$media-width-xxxlarge}) {
      width: bas-object-width(4);
    }
  }
}

.icon-show-delete {
  .info.source {
    .bas-music-icons {
      .bas-music-icon.delete {
        display: inline-block;
      }
    }
  }
}

// Bas object - Image container - legacy
///////////////////////////////

.bas-object-image-container {
  position: relative;
  display: inline-block;
  height: $bas-object-image-height;
  width: $bas-object-image-width;
  top: 0;
  float: left;
  vertical-align: top;
  fill: defaults.$color-highlight-muted;
  overflow: hidden;
  background-color: defaults.$color-black-dark-bg-trans;
  border-right: 2px solid transparent;

  > .source-image {
    @include util.absolute-center();
  }

  > .bas-music-icon {
    width: $bas-object-image-svg-width;
    padding: $bas-object-image-svg-padding;
  }

  > .player-playing-cover {
    height: $bas-object-image-height;
    width: $bas-object-image-width;
    padding: 0;
    opacity: 1;
  }

  .bas-image.bas-image-size-icon > svg {
    width: 60%;
  }
}

// Bas object - Image container
///////////////////////////////

//$bas-object-image-container-2-width: 25%;
$bas-object-image-container-2-width: 6em;
$bas-object-image-container-2-min-width: 3em;

$bas-object-svg-image-width: 50%;

.bas-object-image-container-2 {
  @include util.button-cursor();

  position: relative;
  display: inline-block;
  width: $bas-object-image-width;
  top: 0;
  float: left;
  padding-bottom: $bas-object-image-width;
  fill: defaults.$color-highlight-muted;
  overflow: hidden;
  background-color: defaults.$color-black-dark-bg-trans;
  border-right: 2px solid transparent;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    width: $bas-object-image-container-2-width;
    padding-bottom: $bas-object-image-container-2-width;
  }
}

.bas-object-image-container-cover {
  @include util.absolute-center();

  height: 100%;
  width: 100%;
  padding: 0;
}

.bas-object-image-container-cover-bg {
  @include util.absolute-center();

  height: 100%;
  width: 100%;
  padding: 0;
  background: no-repeat local center;
  background-size: contain;
}

// Bas object - Info
////////////////////

.bas-object-info {
  height: $bas-object-image-height;
  overflow: hidden;
  vertical-align: top;
  fill: $bas-object-info-fill;
}

$bas-object-non-image-width: calc(100% - #{$bas-object-image-width} - 2px);
$bas-object-non-image-width-phone: calc(100% - #{$bas-object-image-container-2-width} - 2px);

$bas-object-header-title-font: 1.2em;
$bas-object-header-title-font-phone: 1.2em;

$bas-object-current-margin: .4em 0 0 0;

.bas-object-info-wrapper {
  position: relative;
  display: inline-block;
  width: $bas-object-non-image-width;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    width: $bas-object-non-image-width-phone;
  }
}

.bas-object-info-header {
  @include util.button-cursor();

  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  top: 0;
  padding: $bas-object-info-header-padding;
  overflow: hidden;
  box-sizing: border-box;
  background-color: defaults.$color-black-dark-bg-trans;
  font-size: $bas-object-info-font-size;
  min-height: calc(#{basstyle_const.$bas-button-toggle-total-height} + #{2 * $bas-object-info-header-vertical-padding});

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $bas-object-info-font-size-phone;
  }
}

.bas-object-header-background {
  position: absolute;
  top: 0;
  right: 0;
  width: $bas-object-non-image-width-phone;
  height: 3em;
  background-color: defaults.$color-black-dark-bg-trans;
  z-index: -3;
}

.bas-object-info-header-title {
  flex: 1 1 auto;
  min-width: 0;
  font-size: $bas-object-header-title-font;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $bas-object-header-title-font-phone;
  }
}

.bas-object-info-header-join {
  display: none;
  flex: 0 0 auto;
  margin-left: 1em;
}

.bas-object-button-join-content-icon {
  display: none;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    display: inline-block;
  }
}

.bas-object-button-avroom-select-content-icon {
  @include util.absolute-match-parent();

  svg {
    @include util.absolute-center();

    width: 55%;
    fill: defaults.$color-foreground;
  }
}

.bas-object-button-join-content-text {
  display: inline-block;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    display: none;
  }
}

.bas-object-header-toggle {
  margin-left: 1em;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    margin-left: .4em;
  }
}

.bas-object-header-dsp {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: .2em;
  margin-left: 1em;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    margin-left: .4em;
  }

  &:active {

    svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

.bas-object-header-icon-wrapper {
  width: 20px;
  height: 20px;

  > svg {
    fill: defaults.$color-light-control-buttons;
  }
}

.bas-object-info-body-current {
  @include defaults.font-roboto-light;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  height: $bas-object-info-current-height;
  padding: $bas-object-info-padding;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: $bas-object-info-font-size;
  box-sizing: border-box;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $bas-object-info-font-size-phone;
    margin: $bas-object-current-margin;
  }
}

.bas-object-info-body-current-icon {
  display: none;
  position: relative;
  flex: 0 0 auto;
  width: $bas-object-info-current-icon-width;
  margin: $bas-object-info-current-icon-margin;
  text-align: center;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
  }
}

.bas-object-info-body-current-icon-container {
  @include util.absolute-center();

  width: 100%;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
  }
}

.bas-object-info-body-current-icon-placeholder {
  opacity: 0;
}

.bas-object-info-body-current-title {
  flex: 1 1 auto;
  min-width: 0;
  text-align: left;
}

.bas-object-info-body-current-title-big {
  @include defaults.font-roboto-bold;
}

.bas-object-info-body-current-subtitle {
  flex: 0 1 auto;
  min-width: 0;
  text-align: left;
}

.bas-object-info-body-current-source {
  flex: 0 0 auto;
  max-width: 100%;
  margin: $bas-object-info-current-source-margin;
  text-align: right;
  color: $bas-object-info-source-name-subtitle-color;
}

.bsc-song-title {
  .bas-object-info-body-current-source {
    max-width: $bas-object-info-current-source-max-width;
  }
}

.bas-object-info-body-bottom {
  position: relative;
  display: inline-block;
  width: $bas-object-non-image-width;
  margin: $bas-object-info-volume-margin;
  padding: $bas-object-info-volume-padding;
  font-size: $bas-object-info-font-size;
  color: defaults.$color-highlight-on-dark;
  text-align: center;
  box-sizing: border-box;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    position: absolute;
    bottom: 0;
  }
}

.bas-object-info-body-bottom-swappable {
  transform: translateY(120%);
  transition: transform defaults.$standard-animation-time defaults.$animation-timing,
    color defaults.$standard-animation-time defaults.$animation-timing;
}

.bas-object-info-body-volume {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bas-object-info-body-volume-icon {
  @include util.button-cursor();

  position: relative;
  flex: 0 0 auto;
  width: $bas-object-info-volume-icon-width;
  height: $bas-object-info-volume-icon-width;

  &:active {
    .bas-object-info-body-volume-icon-mute {
      svg {
        fill: defaults.$color-light-control-buttons-active;
      }
    }
  }
}

.bas-object-info-body-volume-icon-mute {
  @include util.absolute-center();

  width: 100%;
  height: 100%;

  svg {
    @include util.absolute-center();

    width: $bas-object-info-volume-icon-width;
    height: $bas-object-info-volume-icon-width;
    fill: defaults.$color-light-control-buttons;
  }
}

.bas-object-info-body-volume-slider {
  flex: 1 1 auto;
  height: 100%;
  margin: $bas-object-info-volume-slider-margin;
}

.bas-object-info-body-volume-label {
  width: $bas-object-info-volume-non-slider-width;
  font-size: $bas-object-info-volume-font-size;
  text-align: right;
}

.bas-object-info-body-button-wrapper {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.bas-object-info-body-button {
  @include util.absolute-center();
  @include defaults.font-roboto-regular;

  padding: .2em 1em;
  border: 1px solid defaults.$color-highlight-on-dark;
  border-radius: 1em;
  color: defaults.$color-foreground;

  &:active {
    color: defaults.$color-highlight-on-dark;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.bas-object-scroll-container {
  @include util.absolute-match-parent();

  display: flex;
  flex-flow: column nowrap;
  margin-top: 0;
  margin-bottom: 0;
}

.bas-object-scroll-sticky {
  flex: 0 0 auto;
}

.bas-object-scroll {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.bas-rooms-header {

  .bas-objects-header-toggle {
    display: none;
  }

  .bas-objects-header-toggle-icon {
    opacity: 0;
  }

  &.brh-toggle-enabled {

    .bas-objects-header-toggle {
      display: block;
    }

    &.brh-state-home {
      .bas-objects-header-toggle-icon-home {
        opacity: 1;
      }
    }

    &.brh-state-music {
      .bas-objects-header-toggle-icon-music {
        opacity: 1;
      }
    }

    &.brh-state-video {
      .bas-objects-header-toggle-icon-video {
        opacity: 1;
      }
    }

    &.brh-state-thermostats {
      .bas-objects-header-toggle-icon-thermostats {
        opacity: 1;
      }
    }

    &.brh-state-intercom {
      .bas-objects-header-toggle-icon-intercom {
        opacity: 1;
      }
    }
  }
}

// Favourites

.bas-favourites-wrapper {
  @include util.absolute-match-parent();

  overflow-y: auto;
}
