@use 'defaults';
@use 'bas_notch_const';
@use 'util';

$bah-title-margin: .6em;
$bah-outer-margin: 10px;
$bah-big-outer-margin: 16px;
$bah-button-margin: 25px;
$bah-right-margin: $bah-outer-margin;
$bah-left-margin: $bah-button-margin - $bah-outer-margin;
$bah-icon-size: 32px;
$bah-icon-size-p: 24px;
$bah-height: 3em;

$bah-logo-border: 2px;
$bah-logo-border-p: 1px;

$mhh-outer-spacing: 10px;
$mhh-inner-spacing: 16px;

$mhh-outer-icon-width-p: $bah-icon-size-p + $mhh-inner-spacing + $mhh-outer-spacing;
$mhh-inner-icon-width-p: $bah-icon-size-p + 2 * $mhh-inner-spacing;
$mhh-outer-icon-width: $bah-icon-size + $mhh-inner-spacing + $mhh-outer-spacing;
$mhh-inner-icon-width: $bah-icon-size + 2 * $mhh-inner-spacing;

$mhh-transition-delay: defaults.$standard-animation-time * .5;

$mhm-arrow-size: .7em;
$mhm-arrow-room: $mhm-arrow-size + .3em;

// Main Header

// Main app header

// mh = Main Header
// mh-pw padding wrapper
// mhhl = mh Home Logo

.main-header--spacing--top-padding {
  padding-top: defaults.$main-header-height-phone;
  padding-top: calc(#{defaults.$main-header-height-phone} + #{bas_notch_const.$notch-safe-top});

  @media (min-width: #{defaults.$media-phone-width}) {
    padding-top: defaults.$main-header-height;
    padding-top: calc(#{defaults.$main-header-height} + #{bas_notch_const.$notch-safe-top});
  }
}

.main-header--spacing--top-height {
  top: defaults.$main-header-height-phone;
  top: calc(#{defaults.$main-header-height-phone} + #{bas_notch_const.$notch-safe-top});
  height: calc(100% - #{defaults.$main-header-height-phone});
  height: calc(100% - #{defaults.$main-header-height-phone} - #{bas_notch_const.$notch-safe-top});

  @media (min-width: #{defaults.$media-phone-width}) {
    top: defaults.$main-header-height;
    top: calc(#{defaults.$main-header-height} + #{bas_notch_const.$notch-safe-top});
    height: calc(100% - #{defaults.$main-header-height});
    height: calc(100% - #{defaults.$main-header-height} - #{bas_notch_const.$notch-safe-top});
  }
}

.bas-device-android.bas-pref-show-statusbar-disabled {
  .main-header-wrapper,
  .bas-root-header {
    padding-top: bas_notch_const.$notch-safe-no-statusbar-top;
  }

  .main-header--spacing--top-padding {
    padding-top: defaults.$main-header-height-phone;
    padding-top: calc(#{defaults.$main-header-height-phone} + #{bas_notch_const.$notch-safe-no-statusbar-top});

    @media (min-width: #{defaults.$media-phone-width}) {
      padding-top: defaults.$main-header-height;
      padding-top: calc(#{defaults.$main-header-height} + #{bas_notch_const.$notch-safe-no-statusbar-top});
    }
  }

  .main-header--spacing--top-height {
    top: defaults.$main-header-height-phone;
    top: calc(#{defaults.$main-header-height-phone} + #{bas_notch_const.$notch-safe-no-statusbar-top});
    height: calc(100% - #{defaults.$main-header-height-phone});
    height: calc(100% - #{defaults.$main-header-height-phone} - #{bas_notch_const.$notch-safe-no-statusbar-top});

    @media (min-width: #{defaults.$media-phone-width}) {
      top: defaults.$main-header-height;
      top: calc(#{defaults.$main-header-height} + #{bas_notch_const.$notch-safe-no-statusbar-top});
      height: calc(100% - #{defaults.$main-header-height});
      height: calc(100% - #{defaults.$main-header-height} - #{bas_notch_const.$notch-safe-no-statusbar-top});
    }
  }
}

$ios-multitask-button-spacing: 5px;

// New multitasking button on iPadOS 15: if the status bar is NOT shown,
//  we add 5 pixels of padding
.bas-device-ios-15-or-higher.bas-pref-show-statusbar-disabled {
  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    .main-header-wrapper {
      padding-top: calc(#{bas_notch_const.$notch-safe-top} + #{$ios-multitask-button-spacing});
    }
  }
}

// Look in _main.scss for flex box settings
.main-header {
  height: defaults.$main-header-height-phone;
  color: defaults.$color-foreground;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: defaults.$main-header-height;
  }
}

.mh-lisa {
  display: none;
}

.bas-device-lisa {
  .mh-regular {
    display: none;
  }

  .mh-lisa {
    display: block;
  }
}

.mh-pw {
  position: absolute;
}

.mh-do-not-disturb-indicator {
  display: none;
  position: absolute;
  width: 10px;
  right: 5px;

  >svg {
    fill: defaults.$color-red-light;
  }
}

.bas-device--supports-volume-control {

  .mh-has-context {

    &.bas-volume--do-not-disturb {
      .mh-do-not-disturb-indicator {
        display: block;
      }
    }
  }
}

.mh-icon {
  @include util.button-cursor;

  width: $bah-icon-size-p;
  height: 100%;

  .mh-pw {
    width: $bah-icon-size-p;
    height: 100%;
  }

  svg {
    @include util.absolute-center;

    width: 100%;
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$standard-animation-time;
  }

  &:active {
    svg {
      fill: defaults.$color-highlight-on-dark;
      transition: fill 0s;
    }
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    width: $bah-icon-size;

    .mh-pw {
      width: $bah-icon-size;
    }
  }
}

.mh-icon-wrapper {
  @include util.absolute-center;

  width: 100%;
  height: 100%;
}

.mh-icon-trans {
  opacity: 1;
  transition: opacity defaults.$standard-animation-time;
}

.mh-abs-btn {
  position: absolute;
  top: 0;
  height: 100%;
}

.mh-back {
  left: 0;
}

.mh-state-title {
  @include defaults.font-roboto-regular;

  position: absolute;
  top: 0.7em;
  font-size: 1.1em;
  left: $mhh-inner-spacing;
  right: $mhh-outer-icon-width-p;
  transition: opacity defaults.$standard-animation-time;

  @media (min-width: #{defaults.$media-phone-width}) {
    right: $mhh-outer-icon-width;
    text-align: left;
    font-size: 1.7em;
  }
}

.mh-home-logo {
  @include util.button-cursor();

  position: absolute;
  top: 1.1em;
  left: $mhh-outer-icon-width-p;
  right: $mhh-inner-icon-width-p + $mhh-outer-icon-width-p;
  font-size: .7em;

  @media (min-width: #{defaults.$media-phone-width}) {
    left: $mhh-outer-icon-width;
    right: $mhh-inner-icon-width + $mhh-outer-icon-width;
    font-size: 1em;
    top: 1.3em;
  }
}

.mhhl-basalte {
  position: relative;
  display: inline-block;
  height: 1.3em;

  svg {
    height: 100%;
    fill: defaults.$color-foreground;
  }
}

.mhhl-separator {
  position: relative;
  display: inline-block;
  top: .05em;
  width: 1px;
  height: .8em;
  font-size: 1.8em;
  margin-left: .35em;
  margin-right: .35em;
  background-color: defaults.$color-highlight-on-dark;
}

.mhhl-home {
  @include defaults.font-roboto-light;

  display: inline-block;
  font-size: 1.6em;
  color: defaults.$color-light-control-buttons;
}

.mh-home-rooms {
  right: $mhh-outer-icon-width-p;

  @media (min-width: #{defaults.$media-phone-width}) {
    right: $mhh-outer-icon-width;
  }
}

.mh-main {
  position: absolute;
  left: 0;
  height: 100%;
  padding-right: $mhh-inner-spacing;
  text-align: left;
  font-size: 1.1em;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity defaults.$standard-animation-time;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding-right: 0;
    text-align: center;
    font-size: 1.7em;
  }

  .mhm-title {
    transition: color defaults.$standard-animation-time;
  }

  .mhm-arrow {
    svg {
      transition: fill defaults.$standard-animation-time;
    }
  }
}

.mh-show-main {
  .mh-main {
    opacity: 1;
    pointer-events: auto;
    transition-delay: $mhh-transition-delay;
  }
}

// New multitasking button on iPadOS 15: slightly move down the centered
//  header text. If statusbar is shown, we don't need to move it down as much.
.bas-device-ios-15-or-higher {
  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    .mh-main {
      .mhm-title,
      .mhm-arrow {
        // Magic number :)
        transform: translateY(8px);
      }
    }

    &.bas-pref-show-statusbar-enabled {
      .mh-main {
        .mhm-title,
        .mhm-arrow {
          // Magic number :)
          transform: translateY(4px);
        }
      }
    }
  }
}

.mhm-title {
  @include defaults.font-roboto-regular;

  position: relative;
  display: inline-block;
  top: .7em;
  max-width: 100%;
  vertical-align: top;
  left: $mhh-inner-spacing;

  @media (min-width: #{defaults.$media-phone-width}) {
    left: 0;
    top: .6em;
  }
}

.mhm-arrow {
  position: relative;
  display: inline-block;
  top: .9em;
  width: $mhm-arrow-size;
  height: $mhm-arrow-size;
  left: $mhh-inner-spacing;
  vertical-align: top;

  svg {
    @include util.absolute-center;

    width: 100%;
    height: 100%;
    fill: defaults.$color-light-control-buttons;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    left: 0;
  }
}

// Header items spacing
.main-header {

  .mh-icon-home,
  .mh-icon-rooms,
  .mh-state-title {
    opacity: 0;
  }

  .mhm-arrow {
    display: none;
  }

  .mh-back {
    padding-left: $mhh-outer-spacing;
    padding-right: $mhh-inner-spacing;

    .mh-pw {
      left: $bah-outer-margin;
    }
  }

  .mh-home-logo {
    opacity: 0;
    transition: opacity defaults.$standard-animation-time;
    pointer-events: none;
  }

  .mh-main {
    right: $mhh-outer-icon-width-p;

    @media (min-width: #{defaults.$media-phone-width}) {
      right: $mhh-outer-icon-width;
      left: $mhh-outer-icon-width;
    }
  }

  .mh-home-rooms {
    display: none;
    padding-right: $mhh-inner-spacing;
    padding-left: $mhh-inner-spacing;
  }

  .mh-context,
  .mh-tiles {
    display: none;
    right: 0;
    padding-right: $mhh-inner-spacing;
    padding-left: $mhh-inner-spacing;

    .mh-pw {
      right: $mhh-inner-spacing;
    }
  }

  // Toggle classes

  .mh-back {
    left: -$mhh-outer-icon-width-p;
    left: calc(-1 * #{bas_notch_const.$notch-safe-left} - #{$mhh-outer-icon-width-p});
    transform: translateX(0);
    transition: transform defaults.$standard-animation-time;

    @media (min-width: #{defaults.$media-phone-width}) {
      left: -$mhh-outer-icon-width;
      left: calc(-1 * #{bas_notch_const.$notch-safe-left} - #{$mhh-outer-icon-width});
    }
  }

  // Can be handled separately because only the left is affected
  &.mh-has-back {

    .mh-back {
      transform: translateX($mhh-outer-icon-width-p);
      transform: translateX(calc(#{bas_notch_const.$notch-safe-left} + #{$mhh-outer-icon-width-p}));
    }

    .mh-state-title {
      left: $mhh-outer-icon-width-p;
    }

    .mh-main {
      left: $mhh-outer-icon-width-p;
      right: $mhh-outer-icon-width-p;
      padding-right: 0;
    }

    .mhm-title {
      left: 0;
    }

    .mhm-arrow {
      left: 0;
    }

    @media (min-width: #{defaults.$media-phone-width}) {

      .mh-back {
        transform: translateX($mhh-outer-icon-width);
        transform: translateX(calc(#{bas_notch_const.$notch-safe-left} + #{$mhh-outer-icon-width}));
      }

      .mh-state-title {
        left: $mhh-outer-icon-width;
      }

      .mh-main {
        left: $mhh-outer-icon-width;
        right: $mhh-outer-icon-width;
      }
    }
  }

  // Can be handled separately because only the right is affected
  &.mh-has-home-rooms {

    .mh-home-rooms {
      display: block;
    }

    .mh-state-title {
      right: $mhh-inner-icon-width-p + $mhh-outer-icon-width-p;
    }

    .mh-main {
      right: $mhh-inner-icon-width-p + $mhh-outer-icon-width-p;
    }

    @media (min-width: #{defaults.$media-phone-width}) {

      .mh-state-title {
        right: $mhh-inner-icon-width + $mhh-outer-icon-width;
      }

      // Center mh-main in the header
      .mh-main {
        right: $mhh-inner-icon-width + $mhh-outer-icon-width;
        left: $mhh-inner-icon-width + $mhh-outer-icon-width;
      }
    }

    &.mh-show-rooms-icon {

      .mh-icon-home {
        opacity: 0;
      }

      .mh-icon-rooms {
        opacity: 1;
      }
    }
  }

  &.mh-has-context {
    .mh-context {
      display: block;
    }
  }

  &.mh-show-home-icon {
    .mh-icon-home {
      opacity: 1;
    }
  }

  &.mh-show-title-arrow {

    .mh-main {
      @include util.button-cursor;

      .mhm-arrow {
        display: inline-block;
      }

      .mhm-title {
        max-width: calc(100% - #{$mhm-arrow-room});
      }

      &:active {

        .mhm-title {
          color: defaults.$color-highlight-on-dark;
          transition: color 0s;
        }

        .mhm-arrow {
          svg {
            fill: defaults.$color-highlight-on-dark;
            transition: fill 0s;
          }
        }
      }
    }
  }

  &.mh-show-home-logo {

    .mh-home-logo {
      opacity: 1;
      pointer-events: auto;
      transition-delay: $mhh-transition-delay;
    }
  }

  &.mh-show-state-title {

    .mh-state-title {
      opacity: 1;
      transition-delay: $mhh-transition-delay;
    }
  }

  &.mh-title-center {

    .mh-state-title {
      text-align: center;
    }
  }

  &.mh-has-tiles {
    .mh-tiles {
      display: block;
    }
  }

  &.mh-splash-context {

    .mh-home-logo,
    .mh-back,
    .mh-state-title,
    .mh-main,
    .mh-home-rooms {
      visibility: hidden;
      pointer-events: none;
    }

    .mh-context {
      display: block;
    }
  }
}

.bas-device-ellie,
.bas-device-lena {

  .mh-main,
  .mh-home-logo,
  .mh-state-title {
    transition: none;
  }
}

// Bas Main Header

// Header used in content views

// Mixins
@mixin bah-icon() {
  position: relative;
  display: block;
  width: $bah-icon-size-p;
  height: $bah-icon-size-p;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: $bah-icon-size;
    height: $bah-icon-size;
  }

  svg {
    @include util.absolute-center();

    width: 100%;
    height: 100%;
  }

  &:active,
  &.active {
    svg {
      fill: defaults.$color-highlight-on-light;
      transition: fill 0s ease-out;
    }
  }
}

// Base classes
.bas-app-header {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  height: defaults.$main-header-height-phone;

  &.bas-app-header-static {
    position: static;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    height: defaults.$bmsc-header-height;
    //height: $bah-height;
  }
}

.bah-non-phone {
  display: none;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;
  }
}

.bah-light {
  background-color: defaults.$color-library-header;

  svg {
    fill: defaults.$color-library-control;
  }
}

.bah-foreground {
  z-index: 3;
}

.bah-relative {
  position: relative;
}

.bah-dark {
  background-color: defaults.$color-header;

  svg {
    fill: defaults.$color-light-control-buttons;
  }
}

.bah-high {
  height: defaults.$main-header-height-phone;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: defaults.$main-header-height;
  }
}

.bah-static {
  flex: 0 0 auto;
}

.bah-shrinking {
  flex: 0 1 auto;
}

.bah-filling {
  flex: 1 1 auto;
}

.bah-home,
.bah-back {
  @include bah-icon();

  position: absolute;
  height: 100%;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: 100%;
  }
}

.bah-navigation {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.bah-navigation-wrapper {
  height: 100%;
  width: 100%;
  transition: transform defaults.$standard-animation-time;
}

.bah-title {
  @include util.vertical-center();

  position: relative;
  width: calc(100% - #{2 * $bah-outer-margin});
  margin: 0 $bah-outer-margin 0 16px;
  display: flex;
  align-items: baseline;

  &.bah-big-title {
    font-size: .7em;

    .baht-main {
      font-size: 2em;
      color: defaults.$color-foreground;
    }

    .baht-separator {
      font-size: 1.8em;
      margin: 0 $bah-title-margin*0.5;
    }

    .baht-sub {
      font-size: 1.6em;
      color: defaults.$color-light-control-buttons;
    }

    @media (min-width: #{defaults.$media-phone-width}) {
      font-size: 1em;
    }
  }
}

.baht-main {
  @include defaults.font-roboto-regular;
  @include util.ellipsis-overflow();

  position: relative;
  display: inline-block;
}

.baht-logo {
  position: relative;
  display: inline-block;
  height: 1.3em;

  svg {
    fill: defaults.$color-light-control-buttons-f;
    height: 100%;
  }
}

.baht-separator {
  display: inline-block;
  position: relative;
  top: 0.15em;
  width: 1px;
  height: 1em;
  margin: 0 $bah-title-margin;
  background-color: defaults.$color-highlight-on-dark;
}

.baht-sub {
  @include defaults.font-roboto-light;
  @include util.ellipsis-overflow();

  display: inline-block;
}

.bah-delete-button,
.bah-edit-button,
.bah-finish-button,
.bah-custom-image-button {
  @include defaults.font-roboto-regular;

  position: relative;
  display: inline-block;
  color: defaults.$color-highlight-on-light;
}

.bah-delete-button {
  margin: 0 $bah-right-margin 0 $bah-left-margin;
}

.bah-edit-text,
.bah-custom-image-button,
.bah-finish-button {
  @include defaults.font-roboto-regular;

  padding: 1rem;
  color: defaults.$color-highlight-on-light;
}

.bah-selection {
  @include defaults.font-roboto-regular;

  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: defaults.$color-highlight-on-dark;
  height: 100%;
  padding: 0 .85em;
}

.bah-selection-word {
  position: relative;
  display: none;
  color: white;
  margin-right: .2em;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: inline-block;
  }
}

.bah-selection-emblem {
  background-color: black;
}

.bah-icon {
  @include bah-icon();

  flex: 0 0 auto;
  margin: 0 $bah-right-margin 0 $bah-left-margin;
}

.bah-icon-size-70 {
  svg {
    width: 70%;
  }
}

.bah-icon-size-80 {
  svg {
    width: 80%;
  }
}

// Functional override
.bah-settings {

  .bah-middle-icon,
  .bah-search,
  .bah-finish-button,
  .bah-edit-button,
  .bah-view-button,
  .baht-sub,
  .baht-logo,
  .baht-separator,
  .bah-custom-image-button {
    display: none;
  }

  .bah-edit-done {
    display: none;
  }

  &.bah-single-navigation {
    .bah-back {
      transform: translateX(-$bah-icon-size-p);
    }
  }

  &.bah-double-navigation {
    .bah-back {
      transform: translateX(-$bah-outer-margin - 2 * $bah-icon-size-p);
    }

    .bah-home {
      transform: translateX(-$bah-icon-size-p);
    }
  }

  &.bah-can-navigate {
    &.bah-single-navigation {
      .bah-navigation-wrapper {
        transform: translateX($bah-icon-size-p + $bah-outer-margin);
        width: calc(100% - (#{$bah-icon-size-p + $bah-outer-margin}));
      }
    }

    &.bah-double-navigation {
      .bah-navigation-wrapper {
        transform: translateX(2 * ($bah-icon-size-p + $bah-outer-margin));
        width: calc(100% - #{2 * ($bah-icon-size-p + $bah-outer-margin)});
      }
    }
  }

  &.baht-has-logo {
    .baht-logo {
      display: inline-block;
    }

    .baht-main {
      display: none;
    }
  }

  &.baht-has-sub {
    .baht-separator {
      display: inline-block;
    }

    .baht-sub {
      display: inline-block;
    }
  }

  &.bah-has-middle {
    .bah-middle-icon {
      display: block;
    }
  }

  &.bah-can-edit {
    .bah-edit-button {
      display: block;
    }
  }

  &.bah-can-finish {
    .bah-finish-button {
      display: block;
    }
  }

  &.bah-can-view {
    .bah-view-button {
      display: block;
    }
  }

  &.bah-can-search {
    .bah-search {
      display: block;
    }
  }

  &.bah-is-edit {
    .bah-edit-edit {
      display: none;
    }

    .bah-edit-done {
      display: block;
    }
  }

  &.bah-can-edit-custom-image {
    .bah-custom-image-button {
      display: block;
    }
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    &.bah-single-navigation {
      .bah-back {
        transform: translateX(-$bah-icon-size);
      }
    }

    &.bah-double-navigation {
      .bah-back {
        transform: translateX(-$bah-outer-margin - 2 * $bah-icon-size);
      }

      .bah-home {
        transform: translateX(-$bah-icon-size);
      }
    }

    &.bah-can-navigate {
      &.bah-single-navigation {
        .bah-navigation-wrapper {
          transform: translateX($bah-icon-size + $bah-outer-margin);
          width: calc(100% - (#{$bah-icon-size + $bah-outer-margin}));
        }
      }

      &.bah-double-navigation {
        .bah-navigation-wrapper {
          transform: translateX(2 * ($bah-icon-size + $bah-outer-margin));
          width: calc(100% - #{2 * ($bah-icon-size + $bah-outer-margin)});
        }
      }
    }
  }
}

.lights-settings-header {
  &.bah-can-edit .bah-edit-button {
    display: flex;
  }

  .bah-navigation-wrapper {
    display: flex;
    flex: 1;

    .bah-title {
      align-items: center;
    }
  }
}

// Visual override

.bah-visual {

  &.bah-has-border {
    .bah-border {
      border: $bah-logo-border-p solid defaults.$color-light-control-buttons;
      border-radius: 100%;
      overflow: hidden;
      box-sizing: border-box;

      @media (min-width: #{defaults.$media-phone-width}) {
        border: $bah-logo-border solid defaults.$color-light-control-buttons;
      }

      &:active {
        border-color: defaults.$color-highlight-on-dark;
        transition: border-color 0s;
      }
    }
  }

  &.bah-active-search {
    .bah-search {
      svg {
        fill: defaults.$color-highlight-on-light;
      }
    }
  }
}
