@use 'defaults';
@use 'basstyle_const';
@use 'util';

$page-indicator-height: 20px;
$page-indicator-dot-margin: 5px;
$lisa-page-padding: 8px;
$music-widget-size: 260px;
$lisa-top-icon-size: defaults.$main-header-height-phone;
$lisa-slider-size: 255px;
$lisa-slider-top: 50%;
$top-header-mask-darken: linear-gradient(
  defaults.$color-black-dark-bg-trans-dark 0,
  defaults.$color-black-dark-bg-trans-dark #{defaults.$main-header-height-phone},
  black #{defaults.$main-header-height-phone}
);

// General
.bas-lisa {
  height: 100%;
}

$lisa-pill-button-padding-hor: 1em;
$lisa-pill-button-padding-ver: .8em;
$lisa-pill-button-border-radius: 999px;

.lisa-button {
  @include defaults.font-roboto-regular();

  position: relative;
  border: solid 1px defaults.$color-background-active;
  border-radius: $lisa-pill-button-border-radius;
  transition: color defaults.$standard-animation-time,
    border-color defaults.$standard-animation-time,
    z-index defaults.$standard-animation-time defaults.$standard-animation-time;
  text-align: center;
  padding: $lisa-pill-button-padding-ver $lisa-pill-button-padding-hor;
  box-sizing: border-box;
  color: defaults.$color-foreground;

  &:active {
    @include basstyle_const.bs-btn-active-highlight-transparent-fill;

    transition: none;
  }
}

// Pages / swiper
.lisa-pagination {
  bottom: 0 !important;
  height: $page-indicator-height;
  width: 100vw;
}

.lisa-pagination-darken {

  .swiper-pagination-bullet {
    box-shadow: 0 0 50px 20px defaults.$color-black-bg-trans;
  }
}

.bas-page-bullet {
  opacity: 1;
  margin: 0 4px;
  border-radius: 50%;
  overflow: hidden;
  background-color: initial;

  .bas-page-bullet-color {
    width: 100%;
    height: 100%;
    background-color: defaults.$color-background-light-more-transparent;
    transition: background-color defaults.$standard-animation-time;
  }

  &.swiper-pagination-bullet-active {
    .bas-page-bullet-color {
      background-color: defaults.$color-foreground;
    }
  }
}

.lisa-page-container {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.lisa-page {
  width: 100%;

  .bas-tile-music-widget {
    @include util.absolute-center();

    width: $music-widget-size;
    height: $music-widget-size;
    margin: auto;

    .btm-player {
      border-radius: 6px;
      overflow: hidden;
    }
  }

  .bas-rd-grid-element-scenes {
    top: 50%;
    transform: translateY(-50%);
  }
}

.lisa-page-wrapper {
  @include defaults.font-roboto-regular();

  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: #{$lisa-page-padding + $page-indicator-height} $lisa-page-padding;
  overflow: hidden;
  color: defaults.$color-foreground;
}

.lisa-page-icon {
  height: 80px;

  svg {
    fill: defaults.$color-foreground;
    height: 100%;
  }
}

.lisa-page-home {
  .lisa-page-home-content {
    height: auto;
    width: 300px;
    font-size: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .lisa-page-home-content-top {
      position: relative;

      .lisa-center-clock {
        width: 300px;
        text-align: center;
      }
    }

    .lisa-page-home-datetime {
      text-align: center;
      flex-direction: column;

      .lisa-page-home-date {
        font-size: .9em;
      }

      .lisa-page-home-date {
        font-size: .3em;
        color: defaults.$color-foreground-dark;
        margin-top: -5px;
        padding-bottom: 15px;
      }
    }

    .lisa-page-home-time-meridiem {
      font-size: 0.5em;
      color: defaults.$color-foreground-dark;
    }

    .lisa-page-home-weather {
      padding-bottom: 10px;
      flex-direction: column;

      .lisa-page-home-weather-top {
        display: flex;
        justify-content: center;
      }

      .lisa-page-home-weather-left {
        width: 120px;
      }

      .lisa-page-home-weather-right {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        justify-content: center;
        min-width: 102px;
        padding-left: 20px;
      }

      .lisa-page-home-weather-icon {
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        svg {
          @include util.absolute-center();

          fill: defaults.$color-foreground;
          width: 150%;
        }
      }

      .lisa-page-home-weather-line {
        display: flex;

        .lisa-page-home-weather-line-icon {
          min-width: 18px;
          width: 18px;
          height: 18px;
          display: inline-block;
          position: relative;
          padding-right: 5px;

          svg {
            @include util.absolute-center();

            width: 70%;
            fill: defaults.$color-foreground-dark;
          }
        }

        .lisa-page-home-weather-line-text {
          display: inline-block;
          color: defaults.$color-foreground-dark;
        }
      }

      .lisa-page-home-weather-temperature {
        font-size: 60px;
      }

      .lisa-page-home-weather-summary {
        @include util.ellipsis-overflow();

        font-size: 18px;
        text-align: center;
      }
    }

    .lisa-page-start-scene {
      min-width: 170px;
      max-width: 240px;
      margin: auto;
      position: relative;
      font-size: 0.25em;
      font-weight: 100;
      text-align: center;
      align-items: center;
      box-sizing: border-box;

      &:empty {
        display: none;
      }
    }
  }

  .lisa-page-home-touch-surface {
    width: 280px;
    height: 220px;

    &:active {
      .lisa-page-start-scene {
        @include basstyle_const.bs-btn-active-highlight-transparent-fill();
        @include basstyle_const.bs-btn-instant();
      }
    }
  }
}

.lisa-page-home-content-top,
.lisa-page-home-datetime,
.lisa-page-home-date,
.lisa-page-home-time,
.lisa-page-home-weather {
  display: none;
}

.lisa-page-home-time,
.lisa-page-home-datetime,
.lisa-page-date {
  transition: opacity defaults.$standard-animation-time-long;
  transition-delay: .05s;
}

.bas-lisa {
  &.lisa-start-show-time,
  &.lisa-start-show-weather {
    .lisa-page-home-content-top {
      display: block;
    }
  }

  &.lisa-start-show-time,
  &.lisa-start-show-datetime {
    .lisa-page-home-datetime {
      display: block;
    }
  }

  &.lisa-start-show-time {
    .lisa-page-home-time {
      display: block;
    }
  }

  &.lisa-start-show-weather {
    .lisa-page-home-weather {
      display: flex;
      justify-content: center;
    }
  }

  &.lisa-start-show-date {
    .lisa-page-home-date {
      display: block;
    }
  }

  &.lisa-start-hide-time {
    .lisa-page-home-time,
    .lisa-page-home-datetime,
    .lisa-page-date {
      opacity: 0;
      transition: none;
    }
  }
}

.lisa-page-doors {

  .lisa-page-wrapper {
    padding: 0 0 20px;
  }

  .bas-open-close-devices {
    margin-top: $lisa-page-padding;
  }
}

.lisa-page-lights {

  .lisa-page-icon {
    transform: scale(1.5);
  }
}

.lisa-page-thermostat {
  .lisa-page-wrapper {
    font-size: .8em;
  }

  .lisa-page-thermostat-preview {
    display: none;
    width: 255px;
    height: 255px;
  }

  .lisa-page-thermostat-fan-preview {
    display: flex;
    flex-direction: column;

    .lisa-page-thermostat-fan-preview-icon {
      width: 115px;
      height: 115px;
      position: relative;

      svg {
        @include util.absolute-center();

        width: 80%;
        fill: defaults.$color-foreground;
      }
    }

    .lisa-page-thermostat-fan-preview-fan-mode {
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      font-size: 1.5em;
    }
  }

  &.btw-has-set-point {

    .lisa-page-thermostat-fan-preview {
      display: none;
    }

    .lisa-page-thermostat-preview {
      display: block;
    }
  }
}

.lisa-page-shades {
  .lisa-page-icon {
    transform: scale(1.5);
  }
}

.lisa-rotate-toggle {
  position: fixed;
  right: 0;
  top: 0;
  width: $lisa-top-icon-size;
  height: $lisa-top-icon-size;
  z-index: 20;

  svg {
    @include util.absolute-center();

    width: 50%;
    height: 50%;
    fill: white;
    transition: fill defaults.$standard-animation-time;
    filter: drop-shadow(0 0 4px black);
  }

  &:active {
    svg {
      fill: defaults.$color-highlight-on-light;
      transition: none;
    }
  }
}

.lisa-top-icon {
  width: $lisa-top-icon-size;
  height: $lisa-top-icon-size;
  position: fixed;
  fill: defaults.$color-foreground;
  top: 0;
  z-index: 10;

  &.lisa-top-icon-left {
    left: 0;
  }

  &.lisa-top-icon-right {
    right: 0;
  }

  .lisa-top-icon-inner {
    @include util.absolute-center();

    width: $lisa-top-icon-size;
    height: $lisa-top-icon-size;
  }
}

.lisa-thermostat-rotate-toggle {
  .lisa-top-icon-inner {
    width: 30px;
    height: 30px;
  }
}

.lisa-top-icon-context {
  .lisa-top-icon-inner {
    width: 30px;
    height: 30px;
  }
}

.lisa-music-overlay {
  position: absolute;
  opacity: 0;
  transition: opacity defaults.$standard-animation-time-long;
  pointer-events: none;
  z-index: 2;
  height: defaults.$main-header-height-phone;
}

.lisa-show-music-info {
  .lisa-music-overlay {
    pointer-events: initial;
    opacity: 1;
  }
}

.lisa-swiper-disable {

  .swiper-pagination {
    display: none;
  }
}

.lisa-music-source-name {
  @include defaults.font-roboto-regular();
  @include util.ellipsis-overflow();

  height: 100%;
  margin-left: defaults.$main-header-height-phone;
  align-items: center;
  font-size: 1.1em;
  color: defaults.$color-foreground;
  box-sizing: border-box;
  line-height: defaults.$main-header-height-phone;
  max-width: #{defaults.$lisa-size - 2 * defaults.$main-header-height-phone};
}

.lisa-music-widget-wrapper {
  @include util.absolute-match-parent();
}

.lisa-rotate-toggle {
  &.lisa-rotate-toggle-flip {
    opacity: 1;
    transition: opacity defaults.$standard-animation-time_long;
    transition-delay: 1.4s;

    &:not(.btm-state-now-playing) {
      opacity: 0;
      pointer-events: none;
      transition-delay: 0s;
    }
  }
}

.lisa-list {
  padding: #{defaults.$main-header-height-phone + $lisa-page-padding} $lisa-page-padding $lisa-page-padding $lisa-page-padding;
  height: calc(100% - #{defaults.$main-header-height-phone + $lisa-page-padding});
  overflow: auto;

  .room-setting-group {
    margin: 0;
    width: 100%;
  }

  .rs-item .rs-header {
    // Magic number to make 4 elements fit perfectly on Lisa screen
    height: 67px;
  }

  .rs-item {
    font-size: 1.05em;
  }
}

.bas-device-lisa {
  .lisa-blackout-top {
    &::after {
      content: '';
      position: fixed;
      height: defaults.$main-header-height-phone;
      width: 100%;
      background-color: defaults.$color-background;
      top: 0;
      left: 0;
    }
  }
}

// Slider
.slider-center-touch-surface {
  position: absolute;
  width: 165px;
  height: 165px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
}

// Pill buttons
$lisa-pill-buttons-padding: 5%;

.lisa-pill-buttons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: auto;
  bottom: $lisa-pill-buttons-padding;

  .lisa-pill-button {
    flex: 0 0 65px;
    max-height: calc(51px - .8em * 2 - 2px);
    margin-left: -1px;
    z-index: 0;
    min-height: 51px;

    .lisa-pill-button-icon {
      @include util.absolute-match-parent();

      > div {
        @include util.absolute-center();
      }

      svg {
        fill: defaults.$color-foreground;
        transition: fill defaults.$standard-animation-time;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:first-child:last-child {
      flex-basis: 51px;
      border-radius: $lisa-pill-button-border-radius;
      // Pill button is both first and last: only one pill button
      //  -> Shift upwards
      top: -.8em;
    }

    &:active {
      z-index: 1;

      .lisa-pill-button-icon svg {
        fill: defaults.$color-highlight-on-dark;
        transition: none;
      }
    }
  }
}

.lisa-pill-button-settings-icon {
  height: 21px;
  width: 21px;
  position: relative;

  svg {
    @include util.absolute-center();

    width: 115%;
  }
}

// Light page UI
.lisa-light-main-slider {
  @include util.absolute-center();

  height: $lisa-slider-size;
  width: $lisa-slider-size;
  top: $lisa-slider-top;
  display: none;
  transition: opacity defaults.$standard-animation-time-long;

  .btd-control-buttons {
    display: none;
  }

  .btd-slider-svg {

    .btd-slider-path {
      stroke: defaults.$color-highlight-on-dark;
    }
  }
}

.lisa-lights {
  .lisa-light-on-off {
    @include util.absolute-center();

    height: 100px;
    width: 100px;

    svg {
      top: 47.5%;
      fill: defaults.$color-grey;
      transition: fill defaults.$standard-animation-time;
    }

    &:active {
      svg {
        fill: defaults.$color-highlight-on-dark;
        transition: none;
      }
    }
  }

  .modal-card-color-temp {
    &::before {
      @include util.absolute-match-parent;

      content: '';
      background-color: black;
    }
  }

  &.lisa-light--on {
    .lisa-light-on-off {
      svg {
        fill: defaults.$color-highlight-on-dark;
      }
    }
  }
}

.lisa-light-brightness,
.lisa-light-white-value {
  @include defaults.font-roboto-light();
  @include util.absolute-center();

  color: white;
  font-size: 3.8em;
  opacity: .9;
  pointer-events: none;
}

.lisa-light-brightness {
  display: none;
}

.lisa-lights {
  width: 100%;
  height: 100%;

  .lisa-light-color {
    height: 100%;
    width: 100%;

    .lisa-light-color-icon {
      border-radius: 50%;
      border: solid 2px white;
      height: 24px;
      width: 24px;
    }
  }

  .lisa-light-color {

    .lisa-light-setting-sliders {
      svg {
        width: 115%;
      }
    }
  }

  .lisa-light-toggle-icon {
    width: 27px;
    height: 27px;

    svg {
      @include util.absolute-center();

      top: 47.5%;
      width: 100%;
      fill: defaults.$color-grey;
    }
  }

  &.lisa-light--on {
    .lisa-light-toggle-icon {
      svg {
        fill: defaults.$color-highlight-on-dark;
      }
    }
  }

  .lisa-pill-button {

    &:active {
      .lisa-light-toggle-icon {
        svg {
          fill: defaults.$color-highlight-on-dark;
        }
      }
    }
  }

  .lisa-light-color-icon {
    display: none;
  }

  .lisa-light-color-picker {
    height: 245px;
    width: 245px;
  }

  .lisa-light-color-settings {
    opacity: 0;
    transition: opacity defaults.$standard-animation-time-long;
    pointer-events: none;
  }

  .lisa-light-sliders {
    opacity: 0;
    pointer-events: none;
    transition: opacity defaults.$standard-animation-time-long;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .dsp-block-label,
    .dsp-block-slider-value {
      color: defaults.$color-foreground;
    }

    .dsp-block:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .lisa-light-buttons {
    opacity: 1;
    transition: opacity defaults.$standard-animation-time-long;
  }

  &.brs-show-mode {
    .modal-card-color-mode {
      display: none;
    }

    &.lisa-light-show-settings {
      .modal-card-color-mode {
        display: block;
      }
    }
  }

  &.brs-show-white-brightness {
    .lisa-light-white-slider {
      display: none;
    }

    &.lisa-light-show-settings {
      .lisa-light-white-slider {
        display: block;
      }
    }
  }

  &.brs-show-temp {
    .lisa-light-temp-slider {
      display: block;
    }

    &.lisa-light-show-settings {
      .lisa-light-temp-slider {
        display: block;
      }
    }
  }

  &.brs-show-slider {

    &.lisa-light-show-settings {
      .lisa-light-brightness-slider {
        display: none;
      }
    }

    .lisa-light-brightness-slider {
      display: block;
    }

    .lisa-light-temp-slider {
      display: none;
    }

    .lisa-light-brightness {
      display: block;
    }
  }

  &.brs-show-temp,
  &.brs-show-slider {
    .lisa-light-on-off {
      display: none;
    }
  }

  &.brs-show-temp {
    .lisa-light-setting-sliders {
      display: block;
    }
  }

  &.lisa-light-show-settings {
    .lisa-light-color-settings {
      opacity: 1;
      pointer-events: initial;
    }

    .lisa-light-buttons,
    .lisa-light-on-off {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.lisa-light-show-slider-toggle {

    .lisa-light-slider-on-off {
      display: block;
    }
  }
}

// Thermostat page UIs

.lisa-thermostats {
  $rotation-delay: .8s;

  @include util.absolute-match-parent();

  .lisa-thermostat {
    @include util.absolute-match-parent();
  }

  .lisa-thermostat-control {
    font-size: 2.3em;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-20%);
    width: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    color: defaults.$color-foreground-dark;

    &::after {
      --swiper-navigation-size: 28px;
    }
  }

  .lisa-thermostat-scheduler-toggle,
  .lisa-thermostat-rotate-toggle {
    transition: opacity defaults.$standard-animation-time-long;
    transition-delay: .2s;
  }

  .lisa-thermostat-scheduler-toggle {
    pointer-events: none;
    opacity: 0;
    transition-delay: 0s;

    .lisa-scheduler-icon-enabled {
      opacity: 0;
      transition: opacity defaults.$standard-animation-time;
    }

    .lisa-scheduler-icon-disabled {
      transition: opacity defaults.$standard-animation-time;
      opacity: 0.6;
    }
  }

  &.bas-thermostat-detail-rotate-show-settings.btw-can-scheduler {

    .lisa-thermostat-scheduler-toggle {
      pointer-events: initial;
      opacity: 1;
      transition-delay: #{$rotation-delay + defaults.$standard-animation-time};
    }

    .lisa-thermostat-rotate-toggle {
      pointer-events: none;
      opacity: 0;
      transition-delay: $rotation-delay;
    }
  }

  &.bas-scheduler--is-enabled {

    .lisa-scheduler-icon-enabled {
      opacity: 1;
    }

    .lisa-scheduler-icon-disabled {
      opacity: 0;
    }
  }

  .btd-thermostat {
    overflow: hidden;
  }
}

// Shade page UI
.lisa-shade-slider {
  @include util.absolute-center();

  height: $lisa-slider-size;
  width: $lisa-slider-size;
  top: $lisa-slider-top;
  display: none;

  .btd-control-buttons {
    display: none;
  }

  .btd-slider-svg {

    .btd-slider-path {
      stroke: defaults.$color-highlight-on-dark;
    }
  }
}

.lisa-shade-position {
  @include util.absolute-center();
  @include defaults.font-roboto-light();

  color: defaults.$color-foreground;
  font-size: 3.8em;
  opacity: .9;
  display: none;
}

.lisa-shade-extra {
  @include util.absolute-match-parent();

  box-sizing: border-box;
  padding-top: defaults.$main-header-height-phone;
  background-color: black;
  opacity: 0;
  transition: opacity defaults.$standard-animation-time-long;
  pointer-events: none;
}

.lisa-shade-button {
  margin: 8px 0;
  width: 75%;
}

.lisa-shades {
  width: 100%;
  height: 100%;

  .lisa-shade-open,
  .lisa-shade-stop,
  .lisa-shade-close {
    display: none;
  }

  &.bsd-position-has {

    .lisa-shade-slider {
      display: block;
    }
  }

  &.bsd-position-has {

    .lisa-shade-position {
      display: block;
    }

    .lisa-shade-buttons {
      display: none;
    }
  }

  &.bsd-open-has {
    .lisa-shade-open {
      display: block;
    }
  }

  &.bsd-stop-has {
    .lisa-shade-stop {
      display: block;
    }
  }

  &.bsd-close-has {
    .lisa-shade-close {
      display: block;
    }
  }

  &.lisa-shade--show-extra {
    .lisa-shade-extra {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

// Scenes page

.lisa-page-scenes-quad-favourites {
  @include util.absolute-center();

  display: grid;
  grid-template-columns: [col-placeholder-start col-placeholder-side-start col-tile-scene-1 col-tile-scene-3] 1fr [col-tile-scene-2 col-tile-scene-4] 1fr [col-placeholder-end];
  grid-template-rows: [row-placeholder-start row-tile-scene-1 row-tile-scene-2] 1fr [row-tile-scene-3 row-tile-scene-4] 1fr [row-placeholder-end row-placeholder-side-end];
  height: 100%;
  width: 100%;
  grid-gap: 10px;

  .bas-rds-grid-element {
    margin: 0;
    width: 100%;
  }
}

// Cameras page

.lisa-cameras {
  height: 100%;

  .swiper-slide {
    width: 100vw;
    height: 100vw;
  }

  .lisa-camera {

    $lisa-camera-title-line-height: 21px;
    $lisa-camera-title-padding: 8px;
    $lisa-camera-title-height: $lisa-camera-title-line-height + 2 * $lisa-camera-title-padding;

    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .lisa-camera-title {
      @include defaults.font-roboto-regular();

      font-size: 18px;
      line-height: $lisa-camera-title-line-height;
      color: defaults.$color-text-light-transparent;
      padding: $lisa-camera-title-padding;
      margin-bottom: -$lisa-camera-title-height;
    }
  }
}

// Header title

.mh-state-title-lisa {
  @include defaults.font-roboto-regular();
  @include util.ellipsis-overflow();

  width: calc(100% - #{2 * defaults.$main-header-height-phone});
  height: defaults.$main-header-height-phone;
  line-height: defaults.$main-header-height-phone;
  margin: 0 defaults.$main-header-height-phone;
  vertical-align: middle;
  text-align: center;
  left: initial;
  right: initial;
  top: initial;
  color: white;
  font-size: 1.3em;
  opacity: 1;
  position: absolute;
}

.animate-fade {

  &.ng-enter,
  &.ng-leave {
    transition: opacity defaults.$standard-animation-time;
  }

  &.ng-enter-prepare {
    opacity: 0;
  }

  &.ng-enter {
    opacity: 0;
  }

  &.ng-enter-active {
    opacity: 1;
  }

  &.ng-leave {
    opacity: 1;
  }

  &.ng-leave-active {
    opacity: 0;
  }

  &.animate-fade-delay {
    &.ng-enter {
      transition-delay: defaults.$standard-animation-time;
      transition-timing-function: ease-out;
    }

    &.ng-enter-active {
      transition-delay: defaults.$standard-animation-time;
      transition-timing-function: ease-out;
    }

    &.ng-leave {
      transition-timing-function: ease-in;
    }

    &.ng-leave-active {
      transition-timing-function: ease-in;
    }
  }
}

.lisa-expand-touch {
  padding: 1em;
  margin: -1em;
}

// Tiles

.lisa-tile-container {
  overflow: auto;
  height: 100%;

  .bas-tile {
    float: left;
  }

  .bas-tile:first-child:nth-last-child(7),
  .bas-tile:first-child:nth-last-child(7) ~ .bas-tile {
    &:nth-child(7) {
      margin-bottom: 0;
    }
  }

  .bas-tile:first-child:nth-last-child(8),
  .bas-tile:first-child:nth-last-child(8) ~ .bas-tile {
    &:nth-child(7),
    &:nth-child(8) {
      margin-bottom: 0;
    }
  }

  .bas-tile:first-child:nth-last-child(9),
  .bas-tile:first-child:nth-last-child(9) ~ .bas-tile {
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      margin-bottom: 0;
    }
  }
}

.bas-music-widget-advanced-streams,
.bas-music-widget-advanced-favourites {
  padding-top: defaults.$main-header-height-phone;
  height: #{defaults.$lisa-size - defaults.$main-header-height-phone};
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.bas-music-widget-advanced {
  overflow: hidden;
}

$bas-object-group-spacing: 6px;

.bas-object-group-lisa {
  margin: 0;
  width: 100%;

  &:not(:first-child) {
    margin-top: $bas-object-group-spacing;
  }

  .bas-object {
    width: 100%;
    height: #{(defaults.$lisa-size - defaults.$main-header-height-phone - $lisa-page-padding - 4 * $bas-object-group-spacing) * 0.25};
    margin-right: 0;
    scroll-snap-align: end;
    margin-bottom: $bas-object-group-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bas-music-widget-advanced-title {
  @include defaults.font-roboto-regular();

  position: absolute;
  top: 0;
  width: 100%;
  height: defaults.$main-header-height-phone;
  display: flex;
  justify-content: center;
  align-items: center;
  color: defaults.$color-foreground;
  font-size: 1.3em;
}

.lisa-cameras {
  mask: $top-header-mask-darken;
}

.lisa-vertical-sliders {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 1em;
  padding-top: #{defaults.$main-header-height-phone + $lisa-page-padding};
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
  background: black;
  pointer-events: none;
  opacity: 0;
  transition: opacity defaults.$standard-animation-time;

  .lisa-vertical-slider {
    display: flex;
    flex-direction: column;
    height: 100%;

    .lisa-vertical-slider__label {
      @include defaults.font-roboto-regular;

      color: defaults.$color-foreground;
    }

    .lisa-vertical-slider__slider {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1em;

      .lisa-vertical-slider__value {
        @include defaults.font-roboto-regular;

        color: defaults.$color-foreground;

        &.lisa-vertical-slider__slider--padded {
          padding-right: 1.5em;
        }
      }

      .grsw {
        height: 100%;
      }
    }
  }
}

.lisa-light-show-height-balance {
  .lisa-vertical-sliders {
    opacity: 1;
    pointer-events: initial;
  }
}
