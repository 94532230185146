@use 'defaults';
@use 'bas_notch_const';
@use 'basstyle_const';
@use 'util';

$bmsd-width: 300px;
$bmsd-width-s: 200px;

// Master - Detail

.main-section-content {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  right: 0;
  left: $bmsd-width;
  overflow: hidden;
  background-color: defaults.$color-background-light-semi-transparent;
  transform: translateZ(0);

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width + 1}) {
    left: 0;
  }

  &.library-drawer {
    @media (min-width: #{defaults.$media-phone-width}) and (orientation: portrait) {
      left: $bmsd-width-s;
    }
  }

  &.msc--dark {
    background-color: transparent;

    .bmc-content-bg {
      background-color: defaults.$color-black-bg-trans;;
    }

    .bbs {

      &.bbs-active-select:active {
        background-color: defaults.$color-background-light-practically-less-transparent;
      }
    }

    .bbs-section {
      margin-bottom: 0;
    }

    .bbsi {
      color: defaults.$color-foreground;
    }

    .bbs-name {
      color: defaults.$color-foreground-light;
    }

    .bbs-name-subtext {
      color: defaults.$color-foreground-dark;
    }

    .bbs-flex-name {
      .bbs-name-subtext {
        color: defaults.$color-background-shadow;
      }
    }

    .bs-title-line {

      .bs-title-line-txt {
        color: defaults.$color-foreground-light;
      }

      &::after {
        content: none;
      }
    }

    .bbs-title.bs-title-line {
      font-weight: bold;
      font-size: .9em;
    }

    .bmcis-title {
      font-size: 1em;
      font-weight: normal;
    }

    .bas-settings-link {
      color: defaults.$color-foreground-light;

      &:link,
      &:visited,
      &:focus {
        color: defaults.$color-foreground-light;
      }

      &:hover {
        color: defaults.$color-foreground;
      }

      &:active {
        color: defaults.$color-foreground;
      }
    }

    .bbs-separated::before {
      background-color: defaults.$color-separator-on-dark;
    }

    .bs-btn-select {
      border-color: defaults.$color-highlight-on-dark;
      color: defaults.$color-highlight-on-dark;
    }

    .bbs-slider-value,
    .bbs-slider-unit {
      color: defaults.$color-foreground;
    }

    .bbs-disabled {

      .bbs-name,
      .bbs-slider-value {
        color: defaults.$color-background-light-more-transparent;
      }

      .bbs-value {
        color: defaults.$color-background-shadow-light;
      }
    }

    .bmc-list-item {

      &::before {
        content: none;
      }

      .bmci-name {
        color: defaults.$color-foreground;
        font-weight: lighter;
      }

      &:not(.slip-reordering) {
        .bmci-order {
          svg {
            fill: defaults.$color-foreground;
          }
        }
      }
    }

    .bbc-title,
    .bbc-body {
      color: defaults.$color-foreground;
    }

    .bbc-list-item {

      .bbci-name {
        color: defaults.$color-foreground;
      }

      &::before {
        background-color: defaults.$color-background-shadow-dark;
      }
    }

    .bbs-action {
      .bbs-name,
      .bbs-name .bbs-name-subtext {
        color: defaults.$color-highlight-on-dark;
      }

      &.bbs-disabled {
        .bbs-name,
        .bbs-name .bbs-name-subtext {
          color: defaults.$color-background-shadow-light;
        }
      }
    }

    .bas-app-header {
      background-color: defaults.$color-header;
      color: defaults.$color-foreground;
    }

    .bip-name {
      color: defaults.$color-foreground;
    }
  }
}

.main-section-content-music {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: defaults.$main-footer-height-phone;
  bottom: calc(#{defaults.$main-footer-height-phone} + #{bas_notch_const.$notch-safe-bottom});
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @media (min-width: #{defaults.$media-phone-width}) {
    bottom: defaults.$main-footer-height;
    bottom: calc(#{defaults.$main-footer-height} + #{bas_notch_const.$notch-safe-bottom});
  }
}

// Drawer
// bmsd = Bas Main Section Drawer

@mixin bmsd-item-last () {

  &::after {
    border-bottom: none;
  }
}

.main-section-drawer {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: defaults.$color-background-dark-on-dark;
  z-index: 1;
  transform: translateX(0);
  transition: transform defaults.$standard-animation-time;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: $bmsd-width;
    background-color: defaults.$color-header;
    transition: none;
  }

  &.library-drawer {
    @media (min-width: #{defaults.$media-phone-width}) and (orientation: portrait) {
      width: $bmsd-width-s;
    }
  }
}

.bms-has-placeholder {

  .bmsd-item:nth-last-child(2) {
    @include bmsd-item-last;

    &.bas-notch-margin {
      margin-bottom: 0;
    }
  }

  .bmsdi-placeholder {
    display: none;
  }

  &.bms-placeholder-show {

    .bmsd-item:nth-last-child(2) {

      &.bas-notch-margin {
        margin-bottom: 0;
      }
    }

    .bmsdi-placeholder {
      display: block;
    }
  }
}

.bmsd-hide {

  .main-section-drawer {
    transform: translateX(-100%);

    @media (min-width: #{defaults.$media-phone-width}) {
      transform: translateX(0);
    }
  }
}

$bmsd-side-spacing: 1em;

.bmsd-header {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: defaults.$main-header-height-phone;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: defaults.$bmsc-header-height;
  }
}

.bmsdh-transparent {
  border-bottom: 2px solid defaults.$color-light-separator;
}

.bmsdh-button {
  @include util.button-cursor();

  background-color: defaults.$color-background;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: defaults.$color-foreground-selected;
    opacity: 0;
    transition: opacity defaults.$standard-animation-time;
  }

  &:active {
    &::before {
      opacity: 1;
      transition: opacity 0s;
    }

    .bmsdh-icon {
      > svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }

    .bmsdh-title {
      color: defaults.$color-highlight-on-dark;
      transition: color 0s;
    }
  }
}

$bmsd-header-icon-size-p: 24px;
$bmsd-header-icon-size: 28px;

.bmsdh-icon {
  position: relative;
  flex: 0 0 auto;
  width: $bmsd-header-icon-size-p;
  height: 100%;
  margin-left: 10px;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$active-animation-time;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-left: 14px;
    width: $bmsd-header-icon-size;
  }
}

.bmsdh-title {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  min-width: 0;
  margin: 0 .4em 0 $bmsd-side-spacing;
  color: defaults.$color-foreground;
  transition: color defaults.$active-animation-time;
}

.bmsdh-title-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 .4em 0 $bmsd-side-spacing;
}

.bmsdh-title-title {
  flex: 1 1 auto;
  min-width: 0;
}

.bmsdh-badge {
  flex: 0 0 auto;
  margin: 0 .4em 0 .8em;
}

.bmsdh-filler {
  flex: 100 100 auto;
  min-width: 0;
}

.bmsdh-control {
  @include util.button-cursor();

  position: relative;
  flex: 0 0 auto;
  width: 24px;
  height: 100%;
  margin: 0 $bmsd-side-spacing 0 0;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$active-animation-time;
  }

  &.active {
    > svg {
      fill: defaults.$color-highlight-on-light;
      transition: fill 0s;
    }
  }
}

.bmsd-scroll-container {
  flex: 1 1 auto;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.bmsd-scroll-container-wrapped {
    padding-left: 0;
    padding-left: bas_notch_const.$notch-safe-left;
    padding-bottom: 0;
    padding-bottom: bas_notch_const.$notch-safe-bottom;
    box-sizing: border-box;
  }
}

.bmsd-title-header-spacing {
  margin: $bmsd-side-spacing;
}

.bmsd-title-header-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .bmsd-title {
    flex: 1 1 auto;
  }

  .bmsd-title-header-action {
    flex: 0 0 auto;
  }
}

.bmsd-title {
  @include defaults.font-roboto-regular;

  color: defaults.$color-text-light;
  font-size: 1em;
}

.bmsd-title-header-action {
  @include util.button-cursor();

  color: defaults.$color-highlight-on-dark;
}

.bmsd-placeholder {
  @include defaults.font-roboto-light;

  margin: $bmsd-side-spacing;
  text-align: center;
  color: defaults.$color-text-light;
  font-size: 1em;
}

.bmsd-items {
  display: block;
  width: 100%;
  list-style-type: none;
}

.bmsd-items-abs-header {
  position: absolute;
  top: defaults.$main-header-height-phone;
  bottom: 0;

  @media (min-width: #{defaults.$media-phone-width}) {
    top: defaults.$bmsc-header-height;
  }
}

$bmsdi-indicator-arrow-size: 30px;
$bmsdi-indicator-playing-size: 12px;
$bmsdi-order-size: 12px;

.bmsd-item {
  @include util.button-cursor();

  padding: .6em $bmsd-side-spacing;
  box-sizing: border-box;

  &:last-child {
    @include bmsd-item-last;

    &.bas-notch-margin {
      margin-bottom: 0;
    }
  }
}

$bmsdi-placeholder-vertical-spacing: 20px;

.bmsdi-placeholder {
  @include defaults.font-roboto-regular;

  flex: 0 0 auto;
  display: block;
  margin-top: $bmsdi-placeholder-vertical-spacing;
  margin-bottom: $bmsdi-placeholder-vertical-spacing;
  font-size: .8em;
  text-align: center;
  color: defaults.$color-text-light;
}

$bmsdi-order-width: 16px;

.bmsd-item-ellie,
.bmsd-item-lisa,
.bmsd-item-lena {
  display: none;
}

.bmsd-item-exclude-ellie {
  display: block;
}

.bas-device-ellie {
  .bmsd-item-ellie {
    display: block;
  }

  .bmsd-item-exclude-ellie {
    display: none;
  }
}

.bas-device-lisa {
  .bmsd-item-exclude-lisa {
    display: none;
  }

  .bmsd-item-lisa {
    display: block;
  }
}

.bas-device-lena {
  .bmsd-item-exclude-lena {
    display: none;
  }

  .bmsd-item-lena {
    display: block;
  }
}

.bmsd-item-indicator {
  position: relative;

  .bmsdi-order {
    @include util.vertical-center();

    display: none;
    position: absolute;
    width: $bmsdi-order-width;

    svg {
      @include util.absolute-center();
    }
  }

  .bmsdi-name.name-no-ver-margin,
  .bmsdi-name {
    margin-right: $bmsdi-indicator-arrow-size + 4px;
  }

  .bmsdi-indicator {
    position: absolute;
    top: 0;
    right: $bmsd-side-spacing;
  }

  &.slip-reordering {
    .bmsdi-order {
      display: block;
    }

    .bmsdi-name {
      margin-left: $bmsdi-order-width + 4px;
    }

    .bmsdi-name {
      color: defaults.$color-highlight-on-dark;
      transition: color 0s;
    }

    .bmsdi-indicator {

      > svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }
  }

  &.active {

    .bmsdi-name {
      color: defaults.$color-highlight-on-dark;
      transition: color 0s;
    }

    .bmsdi-name-icon {
      svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }

    .bmsdi-indicator {

      > svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }
  }
}

.bmsd-item-alarm {
  position: relative;
  height: 3.2em;
  transition: background-color defaults.$active-animation-time;

  .bmsdi-toggle {
    position: absolute;
    top: .6em;
    left: $bmsd-side-spacing;
  }

  .bmsdi-name-container {
    margin-left: 54px;
    margin-right: 40px;
  }

  .bmsdi-control {
    position: absolute;
    top: .4em;
    right: $bmsd-side-spacing;
  }

  &.selected {
    background-color: defaults.$color-highlight-selected;
    transition: background-color 0s;
  }
}

.bmsd-item-lisa-element {

  .bmsdi-toggle {
    position: absolute;
    top: .7em;
    right: $bmsd-side-spacing;
    transition: opacity defaults.$standard-animation-time;
    opacity: 1;
    pointer-events: initial;
  }

  .bmsdi-order {
    display: block;

    svg {
      fill: defaults.$color-foreground;
      pointer-events: none;
    }
  }

  &.slip-reordering {

    .bmsdi-toggle {
      opacity: 0;
      pointer-events: none;
    }

    .bmsdi-name {
      margin-left: $bmsd-header-icon-size;
    }

    .bmsdi-order {

      svg {
        fill: defaults.$color-highlight-on-dark;
      }
    }
  }

  .bmsdi-name {
    margin-left: basstyle_const.$bsqp-size + 8px;
  }
}

.bmsd-item-player-queue {
  position: relative;

  .bmsdi-order {
    position: absolute;
    display: none;
    top: 0;
    left: $bmsd-side-spacing;
  }

  .bmsdi-name-container {
    margin-left: 0;
    margin-right: 0;
  }

  .bmsdi-title {
    @include defaults.font-roboto-regular;

    font-size: 1em;
  }

  .bmsdi-subtitle {
    @include defaults.font-roboto-light;
  }

  .bmsdi-indicator {
    position: absolute;
    display: none;
    top: 0;
    right: $bmsd-side-spacing;
  }

  &.bmsdi-playing {

    .bmsdi-name-container {
      margin-left: 0;
      margin-right: $bmsdi-indicator-playing-size + 4px;
    }

    .bmsdi-indicator {
      display: block;
    }
  }

  &.slip-reordering {

    .bmsdi-order {
      display: block;
    }

    .bmsdi-name-container {
      margin-left: $bmsdi-order-size + 4px;
      color: defaults.$color-highlight-on-light;
      transition: color 0s;
    }

    &.bmsdi-playing {

      .bmsdi-name-container {
        margin-left: $bmsdi-order-size + 4px;
        margin-right: $bmsdi-indicator-playing-size + 4px;
      }

      .bmsdi-indicator {
        display: block;
      }
    }
  }
}

.bmsd-item-schedule {
  position: relative;

  .bmsdi-order {
    @include util.vertical-center();

    display: none;
    position: absolute;
    width: $bmsdi-order-width;
    // Roughly the center of where a toggle button used to be
    left: 32px;

    svg {
      @include util.absolute-center();
    }
  }

  .bmsdi-toggle {
    position: absolute;
    top: .6em;
    left: $bmsd-side-spacing;
  }

  .bmsdi-name.name-no-ver-margin,
  .bmsdi-name {
    margin-left: basstyle_const.$bas-button-toggle-total-width + 4px;
    margin-right: $bmsdi-indicator-arrow-size + 4px;
  }

  .bmsdi-indicator {
    position: absolute;
    top: 0;
    right: $bmsd-side-spacing;
  }

  &.slip-reordering {

    .bmsdi-order {
      display: block;
    }

    .bmsdi-toggle {
      display: none;
    }

    .bmsdi-name {
      margin-left: $bmsdi-order-width + 4px;
      color: defaults.$color-highlight-on-dark;
      transition: color 0s;
    }

    .bmsdi-indicator {

      svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }

    .brs-scene-widget-indicator {
      display: none;
    }
  }

  // Override bmsd-scheduler styling
  &.bmsd-scheduler.slip-reordering {

    .bmsdi-toggle {
      display: none;
    }
  }

  &.active {

    .bmsdi-name {
      color: defaults.$color-highlight-on-dark;
      transition: color 0s;
    }

    .bmsdi-name-icon {
      svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }

    .bmsdi-indicator {

      > svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }
  }
}

.bmsd-item-delete {
  position: relative;

  .bmsdi-name {
    margin-right: defaults.$bs-btn-circle-size + 4px;
  }

  .bmsdi-btn {
    position: absolute;
    top: .35em;
    right: $bmsd-side-spacing;
  }

  &.selected {

    .bmsdi-name {
      color: defaults.$color-highlight-on-light;
      transition: color 0s;
    }
  }
}

.bmsdi-position-icon {
  width: basstyle_const.$bsqp-size;
  height: basstyle_const.$bsqp-size;
}

.bmsdi-name {
  @include defaults.font-roboto-light;

  margin: .45em 0;
  color: defaults.$color-foreground;
  transition: color defaults.$standard-animation-time;

  &.name-no-ver-margin {
    margin: 0;
  }

  &.title {
    @include defaults.font-roboto-regular;
  }

  &.subtitle {
    color: defaults.$color-foreground-dark;
    font-size: .8em;
  }
}

.bmsdi-name-flex {
  display: flex;
  flex-flow: row nowrap;
}

.bmsdi-name-item {
  flex: 1 1 auto;
  min-width: 0;
}

.bmsdi-name-icon {
  position: relative;
  flex: 0 0 auto;
  width: 24px;
  margin-right: 6px;

  svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$standard-animation-time;
  }
}

.bmsdi-indicator {
  position: relative;
  height: 100%;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$standard-animation-time;
  }

  &.bmsdi-indicator-arrow {
    width: $bmsdi-indicator-arrow-size;
  }

  &.bmsdi-indicator-playing {
    width: $bmsdi-indicator-playing-size;
  }
}

.bmsdi-name-container {
  @include defaults.font-roboto-light;

  color: defaults.$color-foreground;
  transition: color defaults.$active-animation-time;
}

.bmsdi-title {
  display: block;
  font-size: .8em;
}

.bmsdi-subtitle {
  @include defaults.font-roboto-regular;

  display: block;
  font-size: .8em;
}

$bmsdi-control-size: 36px;

.bmsdi-control {
  position: relative;
  width: $bmsdi-control-size;
  height: $bmsdi-control-size;
  border-radius: 100%;
  border: 1px solid defaults.$color-light-control-buttons;

  > svg {
    @include util.absolute-center();

    position: absolute;
    width: 50%;
    fill: defaults.$color-light-control-buttons;
  }

  &:active {
    border-color: defaults.$color-highlight-on-light;

    > svg {
      fill: defaults.$color-highlight-on-light;
    }
  }
}

.bmsdi-order {
  position: relative;
  width: $bmsdi-order-size;
  height: 100%;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-highlight-on-light;
  }
}

.bmsdi-indicator-playing {
  > svg {
    fill: defaults.$color-highlight-on-light;
  }
}

// Footer

$side-queue-footer-height: 4em;
$side-queue-footer-height-phone: 4.5em;

.side-queue-footer {
  position: relative;
  flex: 0 0 auto;
  display: none;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: $side-queue-footer-height-phone;
  font-size: 12px;
  background-color: defaults.$color-background;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: flex;
    font-size: 16px;
    height: $side-queue-footer-height;
  }

  @media (min-width: #{defaults.$media-phone-width}) and (orientation: portrait) {
    height: unset;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: flex-start;

    .bas-mini-player-control {
      margin-top: .5em;
      padding-bottom: .5em;

      &:last-child {
        margin-right: .4em;
        margin-top: .5em;
      }
    }
  }
}

// Sub header

.bas-sub-header {
  @include defaults.font-roboto-regular;

  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 2.5em;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: 3em;
  }
}

.bsh-on-dark {
  color: defaults.$color-foreground;
  background-color: defaults.$color-background-dark-transparent-light;
}

.bsh-filler {
  flex: 1 1 auto;
}

.bsh-element {
  position: relative;
  flex: 0 0 auto;
  height: 100%;
  min-width: 0;
  padding-left: 10px;
  padding-right: 16px;

  &:active {
    color: defaults.$color-highlight-on-dark;
  }
}

.bas-edit-button {
  display: flex;
  align-items: center;
  text-align: right;

  .bas-text-default,
  .bas-text-active {
    line-height: 0;
  }

  .bas-text-default {
    opacity: 1;
  }

  .bas-text-active {
    opacity: 0;
  }
}

.bas-state-editing {

  .bas-edit-button {

    .bas-text-default {
      opacity: 0;
    }

    .bas-text-active {
      opacity: 1;
    }
  }
}

// Content

.bmc-wrapper {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bmc-scroll-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.bmc-scroll-wrapped {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 0;
  padding-left: bas_notch_const.$notch-safe-left;
  padding-right: 0;
  padding-right: bas_notch_const.$notch-safe-right;
  padding-bottom: 0;
  padding-bottom: bas_notch_const.$notch-safe-bottom;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding-left: 0;
  }
}

.bmt-container {
  margin-top: 10px;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-top: 24px;
  }
}

.bmc-header {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  height: defaults.$main-header-height-phone;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: defaults.$bmsc-header-height;
  }
}

.bmc-content {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;

  &.bmc-has-header {
    top: defaults.$main-header-height-phone;

    &.bmc-has-header-non-phone {
      top: 0;
    }

    @media (min-width: #{defaults.$media-phone-width}) {
      // Override top for headers both for phone and non-phone
      &,
      &.bmc-has-header-non-phone {
        top: defaults.$bmsc-header-height;
      }
    }
  }
}

.bmc-flexbox {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}

.bmc-flexbox-header {
  flex: 0 0 auto;
}

.bmc-flexbox-header-padding {
  padding: 10px 16px;
}

.bmc-flexbox-content {
  position: relative;
  flex: 1 1 auto;
}

.bmc-title-light {
  @include defaults.font-roboto-light;

  color: defaults.$color-text-lighter;
}

.bmc-content-check {

  .bmc-content-check-content {
    display: none;
  }

  .bmc-content-check-message {
    display: block;
  }

  &.bmc-content-check-ok {
    .bmc-content-check-content {
      display: block;
    }

    .bmc-content-check-message {
      display: none;
    }
  }
}

.bmc-content-check-content {
  display: block;
}

.bmc-content-check-message {
  display: block;
}
