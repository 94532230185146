@use 'sass:math';
@use 'defaults';
@use 'basstyle_const';
@use 'bas_tile_util';
@use 'util';

// Widget

$btw-margin: 16px;

$btw-segment-temp-width: 55%;

$btw-temp-circle-size: 80%;
$btw-temp-circle-gap: 60deg;
$btw-temp-circle-width: 5px;
$btw-icon-size-wide: 32px;
$btw-icon-size: 26px;
$btw-circle-size: 60%;

//bot = bas object thermostat

.btw-icon {
  position: relative;
  fill: defaults.$color-light-control-buttons;
  height: $btw-icon-size-wide;
  width: $btw-icon-size-wide;

  svg {
    @include util.absolute-center();

    width: 70%;
  }
}

.bas-thermostat-widget {
  @include defaults.font-roboto-regular;

  color: defaults.$color-light-control-buttons-f;
  transition: background-color 1s ease;

  .btw-temp,
  .btw-other,
  .btw-fan,
  .btw-humidity,
  .btw-humidity-alt,
  .btw-current-temperature-alt,
  .btw-ventilation {
    display: none;
  }

  &.btw-mode-heating {
    background-color: defaults.$color-thermostat-heating;
  }

  &.btw-mode-cooling {
    background-color: defaults.$color-thermostat-cooling;
  }

  &.btw-activity-heating {
    .btw-act-heating {
      opacity: 1;
    }
  }

  &.btw-activity-cooling {
    .btw-act-cooling {
      opacity: 1;
    }
  }

  &.btw-has-temperature {
    .btw-circle-sub {
      display: block;
    }

    .btw-current-temperature-alt {
      display: block;
    }
  }

  &.btw-has-set-point {

    .btw-temp {
      display: inline-block;
    }

    .btw-other {
      display: inline-flex;
      width: calc(#{100% - $btw-segment-temp-width} - #{$btw-margin});
      position: initial;
      left: initial;
      transform: initial;

      .btw-line {
        font-size: 1em;
        width: 100%;
        margin: initial;

        .btw-line-item.btw-icon {
          width: $btw-icon-size-wide;
          height: $btw-icon-size-wide;
        }
      }
    }

    .btw-section-alt,
    .btw-current-temperature-alt,
    .btw-humidity-alt {
      display: none;
    }
  }

  &.btw-has-humidity {
    .btw-humidity {
      display: inline-block;
    }

    .btw-humidity-alt {
      display: block;
    }
  }

  &.btw-has-fan-options {
    .btw-fan {
      display: inline-block;
    }
  }
}

.btw {
  position: absolute;
  top: $btw-margin;
  bottom: $btw-margin;
  left: $btw-margin;
  right: $btw-margin;
}

.btw-section {
  position: relative;
  display: inline-block;
  height: 100%;
  vertical-align: text-top;
}

.btw-temp {
  left: 50%;
  transform: translateX(-50%);
  width: calc(#{$btw-segment-temp-width} - #{$btw-margin * 0.5});
  margin-right: $btw-margin;
}

.bas-thermostat-widget {
  &.btw-has-humidity,
  &.btw-has-fan-options {
    .btw-temp {
      left: initial;
      transform: initial;

      .btw-circle {
        margin-left: 5%;
      }
    }
  }
}

.btw-other {
  width: auto;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.btw-circle {
  @include util.absolute-center();

  width: $btw-temp-circle-size;
  padding-bottom: $btw-temp-circle-size;
  border-radius: 50%;
  border-style: none;
  border-color: transparent;
  font-size: 1.15em;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border-color: defaults.$color-light-control-buttons-f;
    border-style: solid;
    border-width: $btw-temp-circle-width;
    border-bottom-color: transparent;
  }

  &::before {
    border-right-color: transparent;
    transform: rotate(-(90deg - $btw-temp-circle-gap) * 0.5);
  }

  &::after {
    border-left-color: transparent;
    transform: rotate((90deg - $btw-temp-circle-gap) * 0.5);
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 0.9em;
  }
}

.btw-circle-main,
.btw-circle-sub {
  position: absolute;
  width: 100%;
  text-align: center;
}

.btw-circle-main {
  top: 50%;
  transform: translateY(-50%);
  transform-style: preserve-3d;

  .btw-temp-desired {
    font-size: 2em;
    fill: defaults.$color-foreground;

    @media (min-width: defaults.$media-query-tablet-portrait) {
      font-size: 2.5em;
    }

    .btw-temp-desired-rounded {
      dominant-baseline: central;
      text-anchor: end;
    }

    .btw-temp-desired-degree {
      dominant-baseline: central;
      text-anchor: start;
    }

    .btw-temp-desired-fraction {
      font-size: .35em;
      dominant-baseline: hanging;
      text-anchor: start;
    }
  }
}

.btw-circle-sub {
  .btw-temp-current text {
    font-size: 0.75em;
    fill: defaults.$color-library-background;

    // SVG alignment properties
    text-anchor: middle;
  }
}

.btw-activity {
  position: absolute;
  top: -4px;
  left: -4px;

  .btw-icon {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease;

    svg {
      width: 80%;
    }
  }

  .btw-act-heating {

    svg {
      width: 75%;
    }
  }
}

.btw-other {
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.btw-line {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  vertical-align: middle;
  white-space: nowrap;

  .btw-icon {
    vertical-align: middle;
  }

  &:last-child {
    margin-bottom: unset;
  }
}

.btw-line-item {
  display: inline-block;
  vertical-align: middle;
}

.btw-block-label {
  border-radius: .5em;
  padding: .4em .6em .3em .3em;
  vertical-align: middle;
  max-width: 100%;
}

.btw-label-bg {
  background-color: rgba(defaults.$color-background, .1);
}

.btw-section-alt {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  left: 40%;
}

.bas-tile-temperature {
  @include bas_tile_util.bas-tile-wrapper();
  @include util.button-cursor();

  padding-bottom: 0;
  background-color: defaults.$color-black-bg-trans;
  display: block;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    background-color: defaults.$color-black-bg-trans;
    float: none;
    box-shadow: none;

    &::before {
      content: none;
      height: 0;
    }
  }
}

.bas-tile-temperature-wide {
  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-query-tablet-portrait - 1}) {
    .btw-icon {
      height: $btw-icon-size;
      width: $btw-icon-size;
    }

    .btw-circle {
      width: $btw-circle-size;
      padding-bottom: $btw-circle-size;
    }

    .btw-temp.btw-has-set-point.btw-has-temperature {
      margin-right: 8px;
      width: 45%;
    }

    .btw-temp.btw-has-set-point.btw-has-temperature.btw-icon {
      width: $btw-icon-size;
      height: $btw-icon-size;
    }
  }
}

// Ventilation only mode
.bas-thermostat-widget.btw-has-fan-options:not(.btw-has-set-point):not(.btw-has-mode) {

  .btw-fan,
  .btw-humidity,
  .btw-current-temperature-alt,
  .btw-humidity-alt,
  .btw-circle-main,
  .btw-circle-sub,
  .btw-circle,
  .btw-temp {
    display: none;
  }

  // Only ventilation, no humidity/temp readings too
  .btw-ventilation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    .btw-ventilation-aspect-ratio {
      height: unset;
      padding-top: 100%;
      position: relative;
    }

    .btw-ventilation-fan {
      width: 20%;
      margin: 0 0 0 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: defaults.$media-query-tablet-portrait) {
        width: 25%;
      }
    }

    .btw-ventilation-icon {
      @include util.absolute-center();

      width: 80%;
      height: 80%;

      svg {
        fill: defaults.$color-light-control-buttons;
      }
    }

    .btw-ventilation-mode {
      width: 50%;
      margin: 0 0 0 6%;
      text-align: left;
    }
  }

  // Only ventilation and temperature and humidity sensors
  &.btw-has-temperature,
  &.btw-has-humidity {
    .btw-ventilation {
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      width: 50%;
      position: relative;

      .btw-ventilation-aspect-ratio {
        height: unset;
        padding-top: 100%;
        position: relative;
      }

      .btw-ventilation-fan {
        width: 35%;
        margin: 0 auto;

        @media (min-width: defaults.$media-query-tablet-portrait) {
          width: 45%;
          margin: 5% auto 0;
        }
      }

      .btw-ventilation-icon {
        margin: auto;
      }

      .btw-ventilation-mode {
        @include util.ellipsis-overflow();

        text-align: center;
        font-size: 1.1em;
        margin: 0 0.5em;
        height: 1em;
      }
    }

    &.btw-has-temperature {
      .btw-current-temperature-alt {
        display: block;
      }
    }

    &.btw-has-humidity {
      .btw-humidity-alt {
        display: block;
      }
    }

    .btw-section-alt {
      display: inline-block;
      left: 55%;

      .btw-line {
        margin-bottom: 0;
        font-size: 1.1em;

        .btw-icon {
          height: 28px;
          width: 28px;
        }
      }
    }
  }
}

// Other

$thermostat-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18);
$thermostat-box-size: 45px;
$week-thermostat-box-size: 32px;
$thermostat-grid-color: #b3b3b3;
$thermostat-hour-offset: math.div(-100%, 24);
$thermostat-hour-width: math.div(100%, 12);
$thermostat-top-margin: 96px;
$thermostat-hours-height: 72px;
$thermostat-hours-hor-margin: 56px;
$thermostat-hours-hor-margin-p: 24px;
$thermostat-container-margins: $thermostat-top-margin $thermostat-hours-hor-margin $thermostat-hours-height + 8px $thermostat-hours-hor-margin;
$thermostat-container-margins-p: $thermostat-top-margin $thermostat-hours-hor-margin-p 0 $thermostat-hours-hor-margin-p;

$thermostat-portrait-top-margin: 72px;
$thermostat-portrait-bottom-margin: 3 * $thermostat-portrait-top-margin;

$thermostat-button-plus-size: 24px;

$week-day-name-width: 6em;
$set-point-box-shadow: 0 2px 2px 0 defaults.$color-background-active-transparent;
$set-point-box-shadow-darker: 0 2px 2px 0 defaults.$color-header;

.bas-thermostat-main-scheduler.bas-scheduler.bsch--page {
  @media (min-width: #{defaults.$media-phone-width}) {
    width: defaults.$media-tablet-landscape;
    max-width: 95%;
    margin-top: defaults.$bs-side-spacing;
    margin-bottom: defaults.$bs-side-spacing;
  }
}

.bas-device-lena {

  .bas-thermostat-main-scheduler.bas-scheduler.bsch--page {
    @media (min-width: #{defaults.$media-phone-width}) {
      width: 100%;
    }
  }
}

.bas-schedule-day {
  width: 100%;
  height: 100%;
}

.schedule-container {
  @include defaults.font-roboto-regular;

  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: #{defaults.$media-phone-width}) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.schedule-hours {
  position: absolute;
  height: $thermostat-hours-height;
  bottom: 0;
  color: $thermostat-grid-color;
  left: $thermostat-hours-hor-margin;
  right: $thermostat-hours-hor-margin;
  white-space: nowrap;
  transform: translate($thermostat-hour-offset, 0);

  @media (max-width: #{defaults.$media-phone-width}) {
    bottom: 0;
    width: 200%;
    left: 0;
    right: 0;
    margin: 0 $thermostat-hours-hor-margin-p;
    transform: none;
  }
}

.schedule-hour {
  @include util.vertical-center();

  position: relative;
  display: inline-block;
  width: $thermostat-hour-width;
  text-align: center;

  @media (max-width: #{defaults.$media-phone-width}) {
    position: absolute;
    width: unset;
    top: 50%;
    transform: translate(-50%, -50%);

    &:nth-child(1) {
      left: $thermostat-hour-width * 0;
    }

    &:nth-child(2) {
      left: $thermostat-hour-width * 1;
    }

    &:nth-child(3) {
      left: $thermostat-hour-width * 2;
    }

    &:nth-child(4) {
      left: $thermostat-hour-width * 3;
    }

    &:nth-child(5) {
      left: $thermostat-hour-width * 4;
    }

    &:nth-child(6) {
      left: $thermostat-hour-width * 5;
    }

    &:nth-child(7) {
      left: $thermostat-hour-width * 6;
    }

    &:nth-child(8) {
      left: $thermostat-hour-width * 7;
    }

    &:nth-child(9) {
      left: $thermostat-hour-width * 8;
    }

    &:nth-child(10) {
      left: $thermostat-hour-width * 9;
    }

    &:nth-child(11) {
      left: $thermostat-hour-width * 10;
    }

    &:nth-child(12) {
      left: $thermostat-hour-width * 11;
    }

    &:nth-child(13) {
      left: $thermostat-hour-width * 12;
    }

    &:nth-child(14) {
      left: calc(100% + #{$thermostat-hours-hor-margin*0.5});
      width: 1px;
      height: 100%;
    }
  }
}

.schedule-info {
  @include defaults.font-roboto-regular;

  position: absolute;
  left: $thermostat-hours-hor-margin-p;
  right: $thermostat-hours-hor-margin-p;
  top: 16px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity defaults.$standard-animation-time;

  @media (min-width: #{defaults.$media-phone-width}) {
    left: $thermostat-hours-hor-margin;
    right: $thermostat-hours-hor-margin;
  }
}

.schedule-name {
  flex: 1 1 auto;
  color: defaults.$color-foreground;
  font-size: 1.5em;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: 1em;
  }
}

.schedule-add-button {
  @include util.button-cursor();

  flex: 0 0 auto;

  svg {
    width: $thermostat-button-plus-size;
    height: $thermostat-button-plus-size;
  }
}

$schedule-remove-top: 16px;
$schedule-remove-text-height: 16px;
$thermostat-remove-height: 32px;
$schedule-remove-padding: ($thermostat-top-margin - $thermostat-remove-height - $schedule-remove-text-height - $schedule-remove-top) *0.5;

.schedule-remove {
  @include defaults.font-roboto-regular;

  position: absolute;
  left: $thermostat-hours-hor-margin-p;
  right: $thermostat-hours-hor-margin-p;
  top: $schedule-remove-top;
  padding: $schedule-remove-padding 0;
  background-color: defaults.$color-remove-dark;
  text-align: center;
  color: defaults.$color-foreground;
  transition: color defaults.$standard-animation-time, background-color defaults.$standard-animation-time, box-shadow defaults.$standard-animation-time, opacity defaults.$standard-animation-time;
  box-shadow: $set-point-box-shadow;
  pointer-events: none;

  @media (min-width: #{defaults.$media-phone-width}) {
    left: $thermostat-hours-hor-margin;
    right: $thermostat-hours-hor-margin;
  }
}

.bas-schedule-day {

  .schedule-remove {
    opacity: 0;
  }
}

.set-point-dragging {
  &.schedule-remove {
    opacity: 1;
  }

  &.schedule-info {
    opacity: 0;
  }
}

.set-point {
  @include util.button-cursor();

  position: absolute;
  width: $thermostat-box-size;
  height: $thermostat-box-size;
  border-radius: 100%;
  background-color: defaults.$color-highlight-on-dark;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: box-shadow defaults.$standard-animation-time, background-color defaults.$standard-animation-time, transform defaults.$standard-animation-time;
  box-shadow: $set-point-box-shadow;
}

.set-point-temperature {
  @include util.absolute-center();

  color: defaults.$color-background;
  transition: opacity defaults.$standard-animation-time;
}

$set-point-container-line-width: math.div(100%, 6);

.set-point-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: $thermostat-container-margins;
  z-index: 10;
  background-size: $set-point-container-line-width 20%;
  background-image: linear-gradient(to right, $thermostat-grid-color 1px, transparent 1px), linear-gradient(to bottom, $thermostat-grid-color 1px, transparent 1px);

  @media (max-width: #{defaults.$media-phone-width}) {
    top: 0;
    bottom: 0;
    width: 200%;
    height: calc(100% - #{$thermostat-hours-height + $thermostat-top-margin + 8px});
    margin: $thermostat-container-margins-p;
    position: relative;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-image: linear-gradient(to left, $thermostat-grid-color 1px, transparent 1px), linear-gradient(to top, $thermostat-grid-color 1px, transparent 1px);
  }
}

.set-point-selected {
  box-shadow: $thermostat-box-shadow;
  z-index: 1;
  background-color: defaults.$color-highlight-on-light-darker;
  transition: box-shadow defaults.$standard-animation-time, background-color defaults.$standard-animation-time;

  .set-point-temperature {
    opacity: 0;
  }

  .set-point-balloon {
    opacity: 1;
  }
}

.set-point-instant {
  transition: box-shadow defaults.$standard-animation-time, background-color defaults.$standard-animation-time;
}

.set-point-deleting {
  pointer-events: none;
}

.set-point-deleted {

  &.set-point,
  .set-point-balloon {
    opacity: 0.3;
  }

  &.schedule-remove {
    color: defaults.$color-foreground-dark;
    background-color: defaults.$color-remove-dark-darker;
    box-shadow: $set-point-box-shadow-darker;
  }
}

.set-point-balloon {
  opacity: 0;
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(black, 0.6);
  border-radius: 5px;
  padding: 1.1em 2.2em;
  transition: opacity defaults.$standard-animation-time;
  pointer-events: none;
}

.set-point-left-value {
  position: absolute;
  right: 55%;
  top: 50%;
  transform: translate(0, -50%);
  text-align: right;
  color: defaults.$color-foreground;
  font-size: 1.2em;
}

.set-point-right-value {
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translate(0, -50%);
  text-align: left;
  color: defaults.$color-foreground;
  font-size: 1.2em;
}

.set-point-center-value {
  @include util.absolute-center();

  text-align: center;
  color: defaults.$color-foreground;
  font-size: 1.2em;
}

.room-setting-item {
  @media (max-width: #{defaults.$media-query-tablet-portrait}) {

    .week-set-point-button {
      top: 25%;

    }

    &::before {
      content: '';
      background-color: defaults.$color-black-dark-bg-trans;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 50%;
    }
  }
}

.week-set-point {
  @include defaults.font-roboto-regular;

  position: absolute;
  width: $week-thermostat-box-size;
  height: $week-thermostat-box-size;
  border-radius: 100%;
  background-color: defaults.$color-highlight-on-dark;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: $set-point-box-shadow;
}

.week-set-point-button {
  @include util.absolute-center();
  @include util.button-cursor();

  position: relative;
  width: 36px;
  height: 36px;
  border: 1px solid defaults.$color-highlight-on-dark;
  border-radius: 100%;
  transition: background-color 1s;

  svg {
    transition: fill 1s;
  }
}

.week-set-point-plus-svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  svg {
    @include util.absolute-center();

    fill: defaults.$color-highlight-on-dark;
    width: $thermostat-button-plus-size;
    height: $thermostat-button-plus-size;
  }
}

.week-set-point-check-svg {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  svg {
    @include util.absolute-center();

    fill: defaults.$color-highlight-on-dark;
    width: 50%;
  }
}

.week-set-point-show-check {
  .week-set-point-check-svg {
    display: block;
  }
}

.week-set-point-hide-plus {
  .week-set-point-plus-svg {
    display: none;
  }
}

.week-set-point-selected {
  &::after {
    @include util.absolute-center();

    content: '';
    width: 60%;
    height: 60%;
    background-color: defaults.$color-highlight-on-dark;
    border-radius: 100%;
  }
}

.week-set-point-highlight {
  background-color: defaults.$color-highlight-on-dark;

  svg {
    fill: defaults.$color-foreground;
  }
}

.rs-control .rs-path-color {
  background-color: defaults.$color-foreground;
}

.rs-control .rs-handle {
  background-color: transparent;
}

.rs-control .rs-bg-color {
  background-color: defaults.$color-background-dark;
}

.rs-outer.rs-border {
  border: none;
}

.rs-inner.rs-border {
  border: none;
}

.rs-animation .rs-transition {
  transition: none;
}

.btd-thermostat-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.btd-target-temperature {
  @include defaults.font-roboto-light;

  position: absolute;
  top: 28%;
  left: 60%;
  transform: translate(-50%, 0);
  text-align: center;
  font-size: 6em;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    font-size: 7em;
  }
}

.btd-current-temperature {
  @include defaults.font-roboto-regular;

  position: absolute;
  bottom: 27%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1.5em;

  .btd-current-text {
    display: inline-block;
  }

  .btd-current-degree {
    display: inline-block;
  }

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    bottom: 30%;
    font-size: 2em;
  }
}

$btd-temperature-color: #ddd;
$btd-icon-size: 45px;
$btd-icon-size-sm: 28px;
$btd-font-size-tablet: 1.2;

$btd-thermostat-size: 300px;
$btd-thermostat-size-l: 400px;
$btd-thermostat-outer-margin: 20px;
$btd-thermostat-middle-margin: 20px;

.btd-slider-container {
  @include util.absolute-center();

  width: $btd-thermostat-size;
  height: $btd-thermostat-size;
  color: $btd-temperature-color;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    width: $btd-thermostat-size-l;
    height: $btd-thermostat-size-l;
  }

  @media (min-width: #{defaults.$media-query-tablet-landscape}) {
    top: 50%;
  }
}

.btd-thermostat {

  .btd-vertical-line,
  .btd-control-buttons,
  .btd-settings-alt-info,
  .btd-alt-humidity,
  .btd-alt-temperature,
  .btd-slider-container,
  .btd-schedule {
    display: none;
  }

  .btd-settings-mode,
  .btd-settings-controls,
  .btd-settings-fan,
  .btd-settings-louver {
    display: none;

    .btd-text-block-selectable {
      color: defaults.$color-foreground-dark;
      border-color: defaults.$color-foreground-dark;
    }
  }

  &.btw-can-scheduler {
    .btd-schedule {
      display: flex;
    }
  }

  &.btw-has-fan-options {
    .btd-settings-fan {
      display: flex;
    }
  }

  &.btw-has-mode-options {
    .btd-settings-mode {
      display: flex;
    }
  }

  &.btw-has-louver-options {
    .btd-settings-louver {
      display: flex;
    }
  }

  &.btw-has-controls {
    .btd-settings-controls {
      display: block;
    }
  }

  &.btw-has-fan-options,
  &.btw-has-controls,
  &.btw-has-mode-options,
  &.btd-settings-louver {

    .btd-slider-container {
      top: 175px;

      @media (min-height: 650px) and (max-width: #{defaults.$media-query-tablet-portrait}) {
        top: 230px;
      }

      @media (min-width: #{defaults.$media-query-tablet-portrait}) {
        top: 300px;
      }

      @media (min-width: #{defaults.$media-query-tablet-landscape}) {
        top: 50%;
        left: 250px;
      }
    }
  }

  &.btw-can-fan {
    .btd-settings-fan {
      .btd-text-block-selectable {
        @include basstyle_const.bs-btn-foreground();

        &:active {
          @include basstyle_const.bs-btn-active-highlight-transparent-fill();
        }
      }
    }
  }

  &.btw-can-mode {
    .btd-settings-mode {
      .btd-text-block-selectable {
        @include basstyle_const.bs-btn-foreground();

        &:active {
          @include basstyle_const.bs-btn-active-highlight-transparent-fill();
        }
      }
    }
  }

  &.btw-can-louver {
    .btd-settings-louver {
      .btd-text-block-selectable {
        @include basstyle_const.bs-btn-foreground();

        &:active {
          @include basstyle_const.bs-btn-active-highlight-transparent-fill();
        }
      }
    }
  }

  .btd-settings-fan,
  .btd-settings-mode,
  .btd-settings-louver {
    .btd-text-block-selectable {
      &.btd-text-block-selected {
        @include basstyle_const.bs-btn-highlight-on-dark();
      }
    }
  }

  &.btw-can-set-point {
    .btd-vertical-line,
    .btd-control-buttons {
      display: block;
    }
  }

  &.btw-has-humidity,
  &.btw-has-temperature {
    .btd-settings-alt-info {
      display: inline-block;

      .btw-line {
        width: auto;
        margin-bottom: 0;

        &.btd-alt-temperature {
          margin-right: 1.5em;
        }
      }
    }
  }

  &.btw-has-set-point {

    .btd-settings-alt-info {
      display: none;
    }

    .btd-slider-container {
      display: block;
    }

    .btd-settings-wrapper {
      top: 350px;

      @media (min-height: 650px) and (max-width: #{defaults.$media-query-tablet-portrait}) {
        top: 420px;
      }

      @media (min-width: #{defaults.$media-query-tablet-portrait}) {
        top: 540px;
      }
    }

    .btd-settings-alt-info {
      display: none;
    }
  }

  &.btw-has-humidity {
    .btd-alt-humidity {
      display: inline-block;
    }
  }

  &.btw-has-temperature {
    .btd-alt-temperature {
      display: inline-block;
    }
  }
}

// Alternate + - buttons on Lisa

#bas-body .lisa-thermostat-buttons {
  display: none;
  bottom: -13%;

  .lisa-pill-button {
    min-height: 51px;
    box-sizing: border-box;
  }
}

#bas-body.bas-device-lisa {
  .btd-thermostat {

    .btd-vertical-line,
    .btd-control-buttons {
      display: none;
    }

    &.btw-can-set-point {
      .lisa-thermostat-buttons {
        display: flex;
      }
    }

    .btd-settings-margin {
      margin: 0;
    }

    .btd-rotate-settings-container {
      @include util.absolute-match-parent()
    ;

      overflow-y: auto;}
  }
}

.btd-settings-wrapper {
  position: absolute;
  left: 5%;
  right: 5%;
  margin-bottom: 20px;
  top: 25px;
}

.btw-can-scheduler {
  .btd-settings-wrapper {
    top: 90px;
  }
}

@media (min-width: #{defaults.$media-query-tablet-landscape}) {
  .btd-thermostat .btd-settings-wrapper {
    @include util.absolute-center();

    max-width: 450px;
  }

  .btd-thermostat.btw-has-set-point  .btd-settings-wrapper {
    @include util.vertical-center();

    left: 475px;
    right: 25px;
    max-width: initial;
  }
}

.btd-settings-container {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;

  @media (min-width: defaults.$media-phone-width) {
    justify-content: flex-start;
  }
}

.btd-settings-container-nowrap {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}

.btd-settings-margin {
  margin-bottom: 30px;
}

.btd-settings {
  position: relative;
  border-radius: 5px;
  padding: 15px 20px;
  background-color: defaults.$color-black-dark-bg-trans;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  font-size: .8em;
  box-sizing: border-box;
  flex-direction: column;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    font-size: 1em;
  }

  @media (min-width: defaults.$media-phone-width) {
    flex-direction: row;
  }
}

.btd-settings-extra-padding {
  padding: 0 0.6em 0 0.5em;
}

.btd-settings-alt-info {
  .btw-line {
    @include defaults.font-roboto-regular();

    color: defaults.$color-foreground;
    margin-bottom: 0;

    svg {
      fill: defaults.$color-foreground;
    }
  }
}

.btd-down {
  position: fixed;
  bottom: 0;
  left: 0;
  transition: opacity defaults.$standard-animation-time;
}

.btd-down,
.btd-up {
  display: none;
}

.btd-heat {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 30px;
}

.btd-activity {
  @include util.absolute-center();

  top: 20%;
}

.lisa-thermostats {
  .btd-activity {
    top: 15%;

    .btd-icon {
      width: $btd-icon-size-sm;
      height: $btd-icon-size-sm;
    }
  }
}

.btd-text-block {
  @include defaults.font-roboto-regular;

  padding: 0.4em .6em;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  color: defaults.$color-foreground;
  min-width: 0;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    font-size: $btd-font-size-tablet * 1em;
  }
}

.btd-text-block-control {
  @include defaults.font-roboto-regular;

  color: defaults.$color-foreground;
  min-width: 0;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    font-size: $btd-font-size-tablet * 1em;
  }
}

.btd-toggle-inline {
  margin: .3em .5em;
  flex: 0 0 auto;
}

.btd-text-block-selectable {
  margin: .3em .5em;
  flex: 0 0 auto;
  min-width: 2rem;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    margin: .5em;
  }

  &.btd-text-block-selected {
    @include basstyle_const.bs-btn-highlight-on-dark();
  }
}

.btd-setting-type {
  text-align: left;
  padding-left: 10px;
  flex: 0 0 auto;
  min-width: 3.5rem;
  max-width: 12rem;
  box-sizing: border-box;
  margin-bottom: 6px;

  &.btd-text-block {
    @include util.ellipsis-overflow();
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.btd-icon {
  display: inline-block;
  position: relative;
  width: $btd-icon-size;
  height: $btd-icon-size;
  vertical-align: top;
  fill: defaults.$color-foreground;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
}

.btd-fan-icon {
  display: none;
}

@media (min-width: defaults.$media-phone-width) {
  .btd-fan-icon {
    display: inline-block;
  }

  .btd-fan-text {
    display: none;
  }
}

.btd-act-cooling,
.btd-act-heating,
.btd-act-inactive {
  @include util.absolute-center();

  opacity: 0;
  fill: black;
}

.btw-has-temperature {
  .btd-act-inactive {
    opacity: 1;
  }
}

.btw-activity-heating {
  .btd-act-heating {
    opacity: 1;
    fill: defaults.$color-highlight-on-dark;
  }

  .btd-act-inactive {
    opacity: 0;
  }
}

.btw-can-set-point {
  .btd-slider-path {
    stroke: defaults.$color-highlight-on-light;
  }
}

.btw-mode-off {
  .btd-target-temperature {
    color: defaults.$color-neutral;
  }

  .btd-slider-path {
    stroke: defaults.$color-neutral;
  }
}

.btw-mode-heating {
  .btd-slider-path {
    stroke: defaults.$color-heating;
  }
}

.btw-activity-cooling {
  .btd-act-cooling {
    opacity: 1;
    fill: #7aaeff;
  }

  .btd-act-inactive {
    opacity: 0;
  }
}

.btw-mode-cooling {
  .btd-slider-path {
    stroke: defaults.$color-cooling;
  }
}

.btd-target-int {
  position: absolute;
  right: 0;
  text-align: right;
}

.btd-target-float {
  position: absolute;
  text-align: left;
  font-size: .4em;
  top: 1.5em;
}

.btd-target-degree {
  position: absolute;
  left: 0;
  text-align: left;
  font-size: .6em;
  top: .1em;
}

.btd-slider-svg {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.btd-slider-path {
  fill: none;
  stroke: defaults.$color-neutral;
  stroke-width: 30;
}

.btd-slider-path-bg {
  fill: none;
  stroke: defaults.$color-light-control-buttons;
  stroke-width: 30;
}

$btd-humidity-color: #bbb;

.btd-humidity-wrapper {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 20%;

  .btd-icon {
    fill: $btd-humidity-color;
    width: 30px;

    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      height: $btd-icon-size * $btd-font-size-tablet;
    }
  }

  .btd-text-block {
    color: $btd-humidity-color;
    padding: 0.85em 0;
  }
}

.btd-title-divider {
  margin-left: .4em;
  margin-right: .4em;
  width: 1px;
  background-color: defaults.$color-text-light;
  height: 1em;
}

// TODO Use defaults
$btd-buttons-color: #ddd;

.rs-border {
  border: 1px solid $btd-buttons-color;
}

.btd-vertical-line {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 30px;
  border-left: 1px solid $btd-buttons-color;
}

.btd-control-buttons {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 5.5%;
  border-radius: 100px;
  border: 1px solid $btd-buttons-color;
  overflow: hidden;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    bottom: 7%;
  }

  .btd-icon {
    position: relative;
    display: inline-block;
    width: 64px;
    border-right: 1px solid $btd-buttons-color;
    transition: fill defaults.$standard-animation-time;

    &:last-child {
      border-right: transparent;
    }

    svg {
      width: 22px;
    }

    &:active {
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

.btd-directive-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: block;

  .btd-current-temperature,
  .btd-humidity-wrapper,
  .btd-target-temperature {
    display: none;
  }

  &.btw-has-temperature {
    .btd-current-temperature {
      display: block;
    }
  }

  &.btw-has-set-point {
    .btd-target-temperature {
      display: block;
    }
  }

  &.btw-has-humidity {
    .btd-humidity-wrapper {
      display: block;
    }
  }
}

.btd-settings-items-wrapper {
  display: inline-flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;

  @media (min-width: defaults.$media-phone-width) {
    justify-content: flex-start;
  }
}

.btd-schedule {
  position: fixed;
  top: 8px;
  right: 5%;
  z-index: 10;

  svg {
    width: 28px;
    fill: defaults.$color-foreground;
    transform: translateY(-1px);
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    top: 12px;

    svg {
      width: 32px;
    }
  }

  @media (min-width: #{defaults.$media-query-tablet-landscape}) {
    right: 25px;
  }
}

.bas-object-image-container-2 {

  .btw-circle {
    margin-left: 0;
  }

  .btw-circle-main {
    top: 50%;
    transform: translateY(-50%);
    transform-style: preserve-3d;

    .btw-temp-desired {
      font-size: 2.5em;
      margin-right: 0.05em;
    }

    .btw-temp-degree {
      font-size: 2.5em;
      margin-left: .48em;
    }
  }

  .bot-ventilation-only-icon {
    @include util.absolute-center();

    transform: translate(-50%, -80%);
    width: 35%;

    svg {
      fill: defaults.$color-foreground;
    }
  }

  .bot-ventilation-only-speed {
    @include util.ellipsis-overflow();

    bottom: 17.5%;
    width: 100%;
    text-align: center;
    position: absolute;
    font-size: 0.8em;
  }
}

.bot-desired {
  font-size: 3em;
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.btw-mode-drying {
  .bot-drying {
    svg {
      @include util.absolute-center();

      fill: defaults.$color-foreground;
    }
  }
}

.bot-current {
  font-size: 1.2em;
  text-align: center;
}

.bot-temp {
  display: inline;
}

.bot-degree {
  display: inline;
}

.bot-icon {
  position: relative;
  width: 16px;
  height: 16px;
  fill: defaults.$color-light-control-buttons;

  svg {
    @include util.absolute-center();

    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.bot-icon-hide {
  position: absolute;
  opacity: 0;
}

.bot-body {
  @include defaults.font-roboto-light();

  padding: 0 1em;
  padding-top: 0.7em;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding: 0 .7em;
    padding-top: 0.2em;
  }
}

.bot-fan-label {
  vertical-align: middle;
  padding: 0 .6em 0 .3em;

  @media (min-width: defaults.$media-phone-width) {
    padding-top: .4em;
    padding-bottom: .3em;
  }
}

.bas-object-thermostat {

  .bot-desired,
  .bot-fan-speed,
  .bot-humidity,
  .bot-current-temperature,
  .bot-fan-ventilation-only,
  .bot-drying,
  .btd-schedule-icon,
  .btd-schedule-toggle {
    display: none;
  }

  &.btw-has-set-point {

    .bot-desired {
      display: block;
    }
  }

  &.btw-has-fan-options:not(.btw-has-set-point):not(.btw-mode-drying),
  &.btw-mode-fan-only {
    .bot-fan-ventilation-only {
      display: block;
    }

    .bot-desired {
      display: none;
    }
  }

  &.btw-mode-drying {

    .bot-drying {
      display: block;
    }

    .bot-desired {
      display: none;
    }
  }

  &.btw-has-temperature {
    .bot-current-temperature {
      display: block;
    }
  }

  &.btw-can-scheduler {
    .btd-schedule-toggle,
    .btd-schedule-icon {
      display: block;
    }
  }

  &.btw-has-fan-options {
    .bot-fan-speed {
      display: inline-block;
    }
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    &.btw-has-humidity {
      .bot-humidity {
        display: inline-block;
      }
    }
  }
}

.bot-background-color {
  &.btw-mode-cooling {

    .bas-object-image-container-2 {
      border-right: 2px solid defaults.$color-cooling;
    }
  }

  &.btw-mode-heating {

    .bas-object-image-container-2 {
      border-right: 2px solid defaults.$color-heating;
    }
  }
}

.bot-tablet-only {
  display: none;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;
  }
}

.bot-text-no-wrap {
  white-space: nowrap;
}

.bot-text {
  padding-top: 0.05em;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding-top: 0.25em;
  }
}

.bot-icon-margin {
  @media (min-width: #{defaults.$media-phone-width}) {
    margin-top: .4em;
  }
}

.bot-icon-margin-right {
  margin-right: 8px;
}

$btd-thermostat-rotate-size: 255px;
$btd-thermostat-rotate-control-width: 50px;
$btd-thermostat-rotate-control-height: 37px;
$btd-thermostat-rotate-control-icon-size: 18px;

.bas-thermostat-rotate {
  height: 100%;
  transform-style: preserve-3d;
  perspective: 1000px;

  .btd-rotate-slider-container {
    @include util.absolute-center();

    height: $btd-thermostat-rotate-size;
    width: $btd-thermostat-rotate-size;
    font-size: 0.8em;
    transform: translate(-50%, -50%) rotateY(0deg);
    transition: transform 1.5s ease-in-out;
    backface-visibility: hidden;
    color: $btd-temperature-color;

    .btd-control-buttons {
      svg {
        width: $btd-thermostat-rotate-control-icon-size;
      }

      .btd-icon {
        width: $btd-thermostat-rotate-control-width;
        height: $btd-thermostat-rotate-control-height;
      }
    }

    .btd-humidity-wrapper {
      font-size: 1.2em;

      .btd-icon {
        width: 25px;
      }
    }
  }

  .btd-rotate-settings-container {
    width: 100%;
    transform: rotateY(180deg);
    transition: transform 1.5s ease-in-out;
    backface-visibility: hidden;
    box-sizing: border-box;
    color: $btd-temperature-color;

    thermostat-detail-settings {
      display: flex;
      flex-direction: column;
      padding-top: defaults.$main-header-height-phone;
      height: #{defaults.$lisa-size - defaults.$main-header-height-phone};
    }
  }
}

.lisa-rotate-thermostat-settings {
  display: block;
}

.lisa-rotate-thermostat-slider {
  display: none;
}

.bas-thermostat-detail-rotate-show-settings {

  .btd-rotate-slider-container {
    transform: translate(-50%, -50%) rotateY(-180deg);
  }

  .btd-rotate-settings-container {
    transform: rotateY(0deg);
  }

  .lisa-rotate-thermostat-settings {
    display: none;
  }

  .lisa-rotate-thermostat-slider {
    display: block;
  }
}

.lisa-thermostat-rotate-toggle {
  display: none;
}

.bas-thermostat-detail-rotate-show-settings-toggle {
  .lisa-thermostat-rotate-toggle {
    display: block;
  }
}

.bas-thermostat-tile {

  .btt-set-point {
    display: none;
  }

  &.btw-has-set-point {

    .btt-set-point {
      display: initial;
    }

    .btt-fan {
      display: none;
    }
  }
}

.btn-scroll-indicator {
  padding: 1em;
  margin: -1em;

  svg {
    width: 28px;
    height: 28px;
  }
}
